import { sortedIndexBy, transform } from 'lodash';
import { AppSettingsContextValue } from 'src/AppSettings';
import {
  AutomationQuickStart,
  Scalars,
  Maybe,
  ProductQuickStartLocalizedImage
} from 'src/generated/gql/graphql';

/* Quickstart Button */
const catalogContentTypes = {
  agent: 'agent',
  listing: 'listing',
  franchise: 'franchise'
};

interface ObjectiveIconProps {
  id: string;
  objective: string;
  appSettings: AppSettingsContextValue;
  isCustom: boolean;
  iconImageUrl: string;
  name: string;
}

export const getObjectiveIcon = ({
  id,
  objective,
  appSettings,
  isCustom,
  iconImageUrl,
  name
}: ObjectiveIconProps) => {
  // check if there is an override icon based on the quickstart id
  const overrideIcon =
    appSettings?.evAssets?.quickstartIconOverrides?.[id]?.icon;

  if (isCustom && iconImageUrl) {
    return (
      <img src={iconImageUrl} alt={`${name} quickstart icon`} width="100%" />
    );
  }

  switch (objective) {
    case 'generate_traffic':
      return (
        <img
          src={overrideIcon || appSettings?.evAssets?.objectiveIconsTraffic}
          alt="generate traffic"
          width="100%"
        />
      );

    case 'website_registrations':
      return (
        <img
          src={
            overrideIcon || appSettings?.evAssets?.objectiveIconsLeadGeneration
          }
          alt="website registration"
          width="100%"
        />
      );

    case 'product_purchase':
      return (
        <img
          src={overrideIcon || appSettings?.evAssets?.objectiveIconsConversion}
          alt="product purchase"
          width="100%"
        />
      );

    case 'generate_contacts':
      return (
        <img
          src={
            overrideIcon || appSettings?.evAssets?.objectiveIconsLeadGeneration
          }
          alt="generate contacts"
          width="100%"
        />
      );

    default:
      return (
        <img
          src={appSettings?.evAssets?.objectiveIconsTraffic}
          alt="generate traffic"
          width="100%"
        />
      );
  }
};

interface ContentStyleParams {
  id: string;
  catalogContentType: string;
  appSettings: AppSettingsContextValue;
  isCustom: boolean;
  customImageUrl: string;
}

export const getContentStyle = ({
  id,
  catalogContentType,
  appSettings,
  isCustom,
  customImageUrl
}: ContentStyleParams) => {
  const foregroundOverride =
    appSettings?.evAssets?.quickstartIconOverrides?.[id]?.foreground;
  const backgroundOverride =
    appSettings?.evAssets?.quickstartIconOverrides?.[id]?.background;

  if (isCustom && customImageUrl) {
    return {
      backgroundImage: `url(${customImageUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    };
  }

  switch (catalogContentType) {
    case catalogContentTypes.listing:
      return {
        backgroundImage: `url(${
          foregroundOverride ||
          appSettings.evAssets.contentTypeIconsListing.foreground
        }), url(${
          backgroundOverride ||
          appSettings.evAssets.contentTypeIconsListing.background
        })`
      };
    case catalogContentTypes.agent:
      return {
        backgroundImage: `url(${
          foregroundOverride ||
          appSettings.evAssets.contentTypeIconsAgent.foreground
        }), url(${
          backgroundOverride ||
          appSettings.evAssets.contentTypeIconsAgent.background
        })`
      };

    case catalogContentTypes.franchise:
      return {
        backgroundImage: `url(${
          foregroundOverride ||
          appSettings.evAssets.contentTypeIconsFranchise.foreground
        }), url(${
          backgroundOverride ||
          appSettings.evAssets.contentTypeIconsFranchise.background
        })`
      };
    default:
      return {
        backgroundImage: `url(${appSettings.evAssets.contentTypeIconsDefault.foreground}), url(${appSettings.evAssets.contentTypeIconsDefault.background})`
      };
  }
};

export const containerWidth = 220;
export const containerBorderWidth = 4;

const totalBackgroundIcons = 5;
export const backgroundSize =
  (containerWidth - containerBorderWidth * 2) / totalBackgroundIcons;

export interface Quickstart {
  id: string;
  architectureId: string;
  name: string;
  isActive: boolean;
  isCustom: boolean;
  iconImageUrl?: Maybe<Scalars['String']>;
  customImageUrl?: Maybe<Scalars['String']>;
  description: string;
  catalogContentType?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  priority: number;
  channels: string[];
  objective: string | undefined;
  productIds: string[];
  productName: string;
  automationQuickStarts: AutomationQuickStart[];
  localizedImages?: ProductQuickStartLocalizedImage[];
  isMlp: boolean;
}

/* Quickstart List */
export const formatQuickStartItems = (activeQuickStartsArray: any) => {
  return transform(
    activeQuickStartsArray,
    (results: Quickstart[], value) => {
      const {
        id,
        priority,
        isActive,
        isCustom,
        iconImageUrl,
        customImageUrl,
        description,
        name,
        product: {
          id: productId,
          architectureId,
          catalogContentType,
          notes,
          name: productName,
          channels,
          isMlp
        },
        automationQuickStarts,
        localizedImages
      } = value;

      const quickstart: Quickstart = {
        id,
        architectureId,
        name,
        isActive,
        isCustom,
        iconImageUrl,
        customImageUrl,
        description,
        catalogContentType,
        notes,
        priority,
        channels: channels || [],
        objective: value?.product?.blueprint?.objective,
        productIds: [productId],
        productName,
        automationQuickStarts,
        localizedImages,
        isMlp
      };

      // we can sort these as we add them like a baws
      const insertIndex = sortedIndexBy(results, quickstart, 'priority');
      results.splice(insertIndex, 0, quickstart);
    },
    []
  );
};
