import { useQuery } from '@apollo/client';
import { isFunction } from 'lodash';

import { ResourceCollection } from 'src/generated/gql/graphql';
import { getResourceCollection } from '../../queries';

const resourceCollectionTypes = {
  facebookAccountId: 'facebook-account-id'
} as const;

interface UseGetResourceCollectionItemOptionsParams {
  selectedCollection?: Omit<ResourceCollection, 'resources'>;
  callback?: () => void;
}

const useGetResourceCollectionItemOptions = ({
  selectedCollection,
  callback
}: UseGetResourceCollectionItemOptionsParams) => {
  const { data: resourceCollectionItemsData } = useQuery(
    getResourceCollection,
    {
      variables: {
        type: resourceCollectionTypes.facebookAccountId,
        key: (selectedCollection as ResourceCollection)?.key
      },
      skip: !selectedCollection
    }
  );

  const resourceCollectionItems =
    resourceCollectionItemsData?.resourceCollection?.resources || [];

  const resourceItemOptions = resourceCollectionItems?.map(resource => ({
    value: resource?.id,
    name: resource?.key
  }));

  if (isFunction(callback)) {
    callback();
  }

  return resourceItemOptions;
};

export default useGetResourceCollectionItemOptions;
