import { AppSettingsContextValue } from 'src/AppSettings';

export interface GlobalStylesProps {
  appSettings: AppSettingsContextValue;
}

const GlobalStyles = ({ appSettings }: GlobalStylesProps) => {
  // Note: This is where we are setting theme based global styles. If you have theme
  //       based overrides put them below. This is also where we will import fonts
  //       based on a url.

  //       Font urls should work like to google fonts where its a link to a page with all the correct
  //       @font-face css properites. For example: https://fonts.googleapis.com/css?family=Lobster
  const primaryFontFamily = appSettings?.fonts?.primary?.fontFamily;
  // const primaryFallback = appSettings?.fonts?.primary?.fallbacks
  const primaryUrl = appSettings?.fonts?.primary?.url;
  // const secondaryFontFamily = appSettings?.fonts?.secondary?.fontFamily
  const secondaryUrl = appSettings?.fonts?.secondary.url;

  const authLogoWidth =
    appSettings?.evSizes?.authLogoWidth || '100% !important';
  const authLogoHeight = appSettings?.evSizes?.authLogoHeight || 'auto';

  const globalStyle = `
            ${primaryUrl && `@import url(${primaryUrl});`}
            ${secondaryUrl && `@import url(${secondaryUrl});`}

            body {
                font-family: ${primaryFontFamily};
                background-color: ${appSettings.colors.bodyBackground};
            }

            .auth0-lock-header-bg-blur {
                background-image: none !important;
                filter: none !important;
                opacity: 1 !important;
            }

            .auth0-lock-submit,
            .auth0-loading-container,
            .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button[data-provider=""],
            .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button[data-provider="auth0"],
            body .auth0-lock.auth0-lock.auth0-lock-mode-loading .auth0-lock-submit
            {
                background-color:
                    ${appSettings.colors.auth0ButtonBackground} !important;
            }
            .auth0-label-submit {
                color:  ${appSettings.colors.auth0ButtonText} !important;
            }
            .auth0-lock.auth0-lock {
                font-family: ${primaryFontFamily} !important;
            }
            .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
                background: ${appSettings.colors.error} !important;
                color:      ${appSettings.colors.errorText} !important;
            }
            .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error * {
                color: ${appSettings.colors.errorText} !important;
            }
            .auth0-lock.auth0-lock .auth0-lock-header-logo {
                width:  ${authLogoWidth};
                height: ${authLogoHeight};
            }
            .auth0-lock-header-bg-solid {
                background-color: #fff !important;
            }
        `;

  // this is less than ideal but we can update it when our global style system changes
  // eslint-disable-next-line react/no-danger
  return <style dangerouslySetInnerHTML={{ __html: globalStyle }} />;
};

export default GlobalStyles;
