import { gql } from 'src/generated/gql';
import {
  OfferType,
  SupervisorOrder,
  OrderItemProcessingStatus
} from 'src/generated/gql/graphql';

export const getArchitectureById = gql(`
  query getArchitectureByIdForPrograms($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      name
    }
  }
`);

export const getBlueprints = gql(`
  query ProductsForPrograms(
    $architectureId: ID!
    $showInactiveBlueprints: Boolean
  ) {
    products(
      architectureId: $architectureId
      showInactive: $showInactiveBlueprints
    ) {
      id
      name
    }
  }
`);

export interface OrderForProgram {
  architecture?: { id: string; name: string };
  architectureId: string;
  id: string;
  name: string;
  isProgramActive: boolean;
  programId: string;
  products: Array<{ id: string; name: string }>;
  product: {
    isAvailable: boolean;
    isMlp: boolean;
    id: string;
    channels: string[];
  };
  billingDetails: {
    amount: number;
    endDate?: string | null;
    offerType: OfferType;
    priceBeforeDiscount?: number | null;
    renewsOn?: string | null;
    startDate: string;
    mostRecentBudgetAdjustment?: {
      updatedBudgetAmount: number;
    } | null;
  };
  orderItem: {
    id: string;
    status: OrderItemProcessingStatus;
    priceAmount: number;
    catalogItems?: {
      edges: Array<{
        node: {
          items: Array<{ id: string }>;
        };
      }>;
    } | null;
  };
  user: { id: string; name: string };
  multiLocationChildProgramDetails: {
    locationId: 'string';
  };
  hierarchy?: string[];
  catalogFilter?: any;
  accessControl?: any;
}

// If you add anything to orders edge here add it to the other orders queries on this page since they share a table
export const getProgramsv2 = gql(`
  query OrdersV2ForPrograms(
    $first: Int!
    $after: String
    $filter: OrdersQueryFilter
    $sort: SortSetting
  ) {
    ordersV2(first: $first, after: $after, filter: $filter, sort: $sort) {
      edges {
        cursor
        node {
          architectureId
          id
          name
          isProgramActive
          programId
          belongsToMultiLocationProgram
          multiLocationProgram {
            id
            name
            architectureId
          }
          multiLocationChildProgramDetails {
            variableValuesAreOverridden
            scheduleIsOverridden
            budgetIsOverridden
            locationId
          }
          products(filter: { bypassPermissions: true }) {
            id
            name
          }
          product {
            isAvailable
            isMlp
          }
          billingDetails {
            amount
            endDate
            offerType
            priceBeforeDiscount
            renewsOn
            startDate
            mostRecentBudgetAdjustment {
              updatedBudgetAmount
            }
          }
          orderItem {
            id
            status
            priceAmount
            catalogItems {
              edges {
                node {
                  items {
                    id
                  }
                }
              }
            }
          }
          user {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);

export interface OrderForAutomation extends SupervisorOrder {
  hierarchy?: string[];
}

// If you add anything to orders edge here add it to the other orders queries on this page since they share a table
export const getAutomations = gql(`
  query Automations(
    $first: Int!
    $after: String
    $filter: AutomationsQueryFilter
    $sort: SortSetting
  ) {
    automations(first: $first, after: $after, filter: $filter, sort: $sort) {
      edges {
        node {
          id
          catalogFilter
          deactivatedAt
          name
          scheduleStartEpochSeconds
          variableValues
          offer {
            productId
            product {
              architectureId
              name
            }
          }
          catalog {
            id
            friendlyName
            fieldMetadata {
              displayMethodId
              displayMethod {
                id
                name
              }
              displayParameters
              displaySortOrder
              fieldName
              displayName
              isRequired
              isEditable
              isHidden
              contentColumnType
              isFilterable
            }
          }
          group {
            id
            name
          }
          user {
            id
            name
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);

export const getProgramsv2Legacy = gql(`
  query OrdersV2ForProgramsLegacy(
    $first: Int!
    $after: String
    $filter: OrdersQueryFilter
  ) {
    ordersV2(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          architectureId
          id
          name
          isProgramActive
          programId
          products(filter: { bypassPermissions: true }) {
            id
            name,
            isMlp
          }
          billingDetails {
            offerType
            amount
            startDate
            endDate
            planId
            planNickname
            interval
            renewsOn
            isSetToCancel
            currentPeriodEnd
            canceledOn
            billingMethod
            promoCode
            priceBeforeDiscount
          }
          adData {
            totalAds
          }
          channels {
            adType
            channels
          }
          orderItem {
            id
            status
            priceAmount
          }
          user {
            id
            name
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);

// If you add anything to orders edge "child orders" here add it to the other orders queries on this page since they share a table
export const getMLPOrderChildren = gql(`
query MLPOrderChildren(
  $multiLocationProgramId: ID!
  $first: Int!
  $after: String
) {
  getMultiLocationProgram(multiLocationProgramId: $multiLocationProgramId) {
    id
    childOrders(first: $first, after: $after) {
      edges {
        cursor
        node {
          architectureId
          id
          name
          isProgramActive
          programId
          belongsToMultiLocationProgram
          multiLocationProgram {
            id
            name
            architectureId
          }
          products(filter: { bypassPermissions: true }) {
            id
            name
          }
          product {
            isAvailable
            isMlp
          }
          billingDetails {
            amount
            endDate
            offerType
            priceBeforeDiscount
            renewsOn
            startDate
            mostRecentBudgetAdjustment {
              updatedBudgetAmount
            }
          }
          orderItem {
            id
            status
            catalogItems {
              edges {
                node {
                  items {
                    id
                  }
                }
              }
            }
          }
          user {
            id
            name
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}
`);

export const getProgramDrafts = gql(`
  query GetProgramDraftsForProgram($first: Int!, $after: String, $filter: ProgramDraftsQueryFilterInput) {
    getProgramDrafts(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          id
          ... on ProgramDraftModel {
            architecture {
              id
            }
            product {
              id
              name
              channels
              isMlp
            }
            catalog {
              id
              friendlyName
            }
            propertiesId
            name
            priceAmount
            catalogFilter
            variableValues
            tierId
          }
          ... on MultiLocationProgramDraftModel {
            architecture {
              id
            }
            product {
              id
              name
              channels
              isMlp
            }
            catalog {
              id
              friendlyName
            }
            propertiesId
            name
            priceAmount
            catalogFilter
            variableValues
            tierId
            overrides {
              locationId
              variableValues
              priceAmount
              tierId
            }
          }
          ... on AutomationDraftModel {
            architecture {
              id
            }
            product {
              id
              name
              channels
              isMlp
            }
            catalog {
              id
              friendlyName
            }
            propertiesId
            name
            priceAmount
            catalogFilter
            variableValues
            tierId
            childOrderDurationDays
            childOrderNameTemplate
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`);

export const getMlpParents = gql(`
  query GetMlpParents($first: Int!, $after: String) {
    getMultiLocationPrograms(first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          groupId
          architectureId
          userId
          name
          priceAmount
          scheduleStart
          scheduleEnd
          catalogId
          variableValues
          timeZoneCode
          tierId
          status
          offerId
          summedPriceAmount
          numChildren
          product {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`);
