import { t } from 'i18next';
import { Box } from '@mui/material';

import { formatDate } from 'src/common/dates';
import { formatPriceWithCents } from 'src/common/numbers';

/* 
    These functions couldn't go into the utilities file because it imports BillingPreferences which these functions
    are imported into creating a circular dependency
 */
export const getBillingHistoryColumnSchema = ({ isTeamsEnabled }) => {
  const billingHistoryColumnSchema = [
    {
      columnName: t('billing.schema.date'),
      accessor: 'billedAt',
      type: 'DATE',
      CellComponent: ({ data }) => {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate({
              date: data,
              format: 'MMM DD, YYYY'
            })}
          </span>
        );
      }
    }
  ];

  if (isTeamsEnabled) {
    billingHistoryColumnSchema.push({
      columnName: t('billing.schema.initiatedBy'),
      accessor: 'billedBy',
      type: 'STRING'
    });
  }

  billingHistoryColumnSchema.push(
    {
      columnName: t('billing.schema.programName'),
      accessor: 'programName',
      type: 'STRING'
    },
    {
      columnName: t('billing.schema.type'),
      accessor: 'offerType',
      type: 'STRING',
      CellComponent: blob => {
        const isRefund = blob?.row?.amount < 0;
        return isRefund ? t('billing.type.refund') : blob?.data;
      }
    },
    {
      columnName: t('billing.schema.card'),
      accessor: 'cardInfo',
      type: 'STRING',
      CellComponent: blob => {
        const cardInfo = blob?.data;
        return cardInfo || '-';
      }
    },
    {
      columnName: t('billing.schema.promoCode'),
      accessor: 'promoCode',
      type: 'STRING',
      CellComponent: blob => {
        const promoCode = blob?.data;
        return promoCode || '-';
      }
    },
    {
      columnName: t('billing.schema.amount'),
      accessor: 'amount',
      type: 'CURRENCY',
      CellComponent: blob => {
        const isRefund = blob?.row?.amount < 0;

        const formattedAmount = formatPriceWithCents(
          isRefund ? Math.abs(blob?.data) : blob?.data
        );

        return (
          <Box
            component="span"
            variant="body2"
            {...(isRefund ? { color: 'success.main' } : {})}
          >
            {`${isRefund ? '+' : ''}${formattedAmount}`}
          </Box>
        );
      }
    },
    {
      columnName: t('billing.schema.orderId'),
      accessor: 'orderId',
      type: 'STRING'
    }
  );

  // There's been some back and forth on this column
  // Commenting it out for now just in case we need to revisit

  // only add cancellation row if an order has a cancellation
  // if (some(billingHistory?.rawRows, 'node.cancellationDetails')) {
  //   billingHistoryColumnSchema.push({
  //     columnName: t('billing.schema.refund'),
  //     key: 'cancellation',
  //     type: 'STRING',
  //     CellComponent: ({ row }) => {
  //       const details = row?.cancellationDetails;
  //       if (!details) {
  //         return '-';
  //       }
  //       return (
  //         <Tooltip
  //           title={
  //             <table>
  //               <tbody>
  //                 <tr>
  //                   <th>
  //                     <Trans i18nKey="programPerf:cancelModal.purchaseAmt">
  //                       Purchase Amount:
  //                     </Trans>
  //                   </th>
  //                   <td>
  //                     {formatPriceWithCents(details?.originalPurchaseAmount)}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <th>
  //                     <Trans i18nKey="programPerf:cancelModal.spendRemaining">
  //                       Spend Remaining:
  //                     </Trans>
  //                   </th>
  //                   <td>
  //                     {formatPriceWithCents(
  //                       details?.amountAfterCalculatedUsage
  //                     )}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <th>
  //                     <Trans i18nKey="programPerf:cancelModal.cancelationFee">
  //                       Processing Fee:
  //                     </Trans>
  //                   </th>
  //                   <td>- {formatPriceWithCents(details?.cancellationFee)}</td>
  //                 </tr>
  //                 <tr>
  //                   <th>
  //                     <Trans i18nKey="programPerf:cancelModal.totalRefund">
  //                       Total Refund:
  //                     </Trans>
  //                   </th>
  //                   <td>{formatPriceWithCents(details?.refundAmount)}</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           }
  //         >
  //           <Box
  //             sx={{
  //               textDecoration: 'underline',
  //               cursor: 'pointer'
  //             }}
  //           >
  //             {formatPriceWithCents(details?.refundAmount)}
  //           </Box>
  //         </Tooltip>
  //       );
  //     }
  //   });
  // }

  return billingHistoryColumnSchema;
};

export const getMediaBillingHistoryColumnSchema = () => {
  return [
    {
      columnName: t('billing.mediaSchema.date'),
      accessor: 'createdOn',
      type: 'DATE',
      CellComponent: ({ data }) => {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate({
              date: data,
              format: 'MMM DD, YYYY'
            })}
          </span>
        );
      }
    },
    {
      columnName: t('billing.mediaSchema.id'),
      accessor: 'mediaAssetId',
      type: 'ID'
    },
    {
      columnName: t('billing.mediaSchema.type'),
      accessor: 'mediaType',
      type: 'STRING'
    },
    {
      columnName: t('billing.mediaSchema.source'),
      accessor: 'mediaSource',
      type: 'STRING'
    },
    {
      columnName: t('billing.mediaSchema.card'),
      accessor: 'cardInfo',
      type: 'STRING'
    },
    {
      columnName: t('billing.mediaSchema.amount'),
      accessor: 'amount',
      type: 'CURRENCY',
      CellComponent: blob => {
        return formatPriceWithCents(blob?.data);
      }
    }
  ];
};
