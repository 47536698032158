import { head } from 'lodash';

import { LeadConnection, LeadField } from 'src/generated/gql/graphql';
import { LeadFieldFlattened, Row } from 'src/pages/Leads/LeadsTable';

const underscoreRegExp = /_/g;
const firstLetterRegExp = /^\w/;

export const formatRowDataEdge = (data: LeadConnection['edges']): Row[] => {
  if (!data) {
    return [];
  }

  return data.reduce((accum: Row[], edge) => {
    if (!edge?.node) {
      return accum;
    }

    const { node } = edge;

    const baseRow: Row = {
      leadId: node.id,
      key: node.id,
      architectureId: node.architectureId,
      programId: node.programId,
      orderId: node.orderId,
      date: node.submittedAt
    };

    // flatten fields
    const fieldData = node.fields.reduce(
      (fieldAccum: LeadFieldFlattened, field: LeadField): any => {
        const firstValue = head(field.values);

        // field specific data
        return {
          ...fieldAccum,
          [field.name]: firstValue,
          ...(field.name === 'first_name' && {
            name: `${firstValue} ${fieldAccum.name || ''}`.trim()
          }),
          ...(field.name === 'last_name' && {
            name: `${fieldAccum.name || ''} ${firstValue}`.trim()
          }),
          ...(field.name === 'name' && {
            name: firstValue
          }),
          ...((field.name === 'phone_number' || field.name === 'phone') && {
            phone: firstValue
          })
        };
      },
      {}
    );

    return [...accum, { ...baseRow, ...fieldData }];
  }, []);
};

export const rowDataFieldsToExclude = new Set([
  'name',
  'first_name',
  'last_name',
  'email',
  'phoneNumber',
  'leadId',
  'key',
  'architectureId',
  'programId',
  'orderId',
  'date',
  'phone_number',
  'phone'
]);

export const extractLeadQuestions = (
  fields: {
    [key: string]: string;
  } = {}
): { name: string; value: string }[] => {
  return Object.entries(fields)
    .filter(([key]) => !rowDataFieldsToExclude.has(key))
    .map(([key, value]) => ({
      name: key
        .replace(underscoreRegExp, ' ')
        .replace(firstLetterRegExp, c => c.toUpperCase()),
      value: value?.replace(underscoreRegExp, ' ') || ''
    }));
};
