import { gql } from 'src/generated/gql';

export const getBillingHistory = gql(`
  query BillingHistory($first: Int!, $after: String) {
    billingHistory(first: $first, after: $after) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          offerType
          status
          amount
          startDate
          endDate
          orderItemId
          interval
          renewsOn
          createdOn
          stripeSourceId
          billedAt
          description
          cardInfo
          promoCode
          architectureId
          orderId
          programId
          programName
          billedBy
          cancellationDetails {
            originalPurchaseAmount
            amountAfterCalculatedUsage
            refundAmount
            cancellationFee
            cancellationDate
          }
        }
      }
    }
  }
`);

export const getMediaBillingHistory = gql(`
  query mediaBillingHistory {
    mediaBillingHistory(first: 20) {
      edges {
        node {
          mediaAssetId
          amount
          mediaType
          mediaSource
          cardInfo
          createdOn
        }
      }
    }
  }
`);

export const userNotificationPreferences = gql(`
  query UserNotificationPreferences {
    userNotificationPreferences {
      id
      userId
      notificationTypeId
      medium
      emailAddress
      phoneNumber
      isActive
    }
  }
`);

export const autoresponderLeadPreferences = gql(`
  query AutoresponderLeadPreferences {
    autoresponderLeadPreferences {
      id
      userId
      isSmsEnabled
      isEmailEnabled
      smsBody
      emailSubject
      emailBody
    }
  }
`);

export const availableNotificationTypes = gql(`
  query AvailableNotificationTypes {
    availableNotificationTypes {
      id
      code
      name
      isActive
    }
  }
`);
