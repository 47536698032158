import {
  Paper,
  Box,
  Typography,
  Divider,
  Alert,
  FormHelperText
} from '@mui/material';
import { styled } from '@mui/system';
import { t } from 'i18next';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { json } from '@codemirror/lang-json';
import CodeMirror, {
  EditorView,
  ReactCodeMirrorRef
} from '@uiw/react-codemirror';

import { Heading } from 'src/components/PageElements';
import { translateMaps } from 'src/common/templateTranslator';

import ExpressionExampleButton from 'src/pages/Admin/HandlebarMapper/ExpressionExampleButton';

const PageSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: `${theme.spacing(2)} 0`,
  display: 'flex',
  flexDirection: 'column'
}));

const InputSection = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`
}));

const initialJsonValue = {
  userMetadata__first: 'Steve',
  userMetadata__last: 'Zissou',
  __var__: {
    creature: 'JaguarShark'
  },
  headline: 'This is a revenge adventure'
};

const HandlebarMapper = () => {
  const [handlebarsValue, setHandlebarsValue] = useState('');
  const [jsonValue, setJsonValue] = useState(
    JSON.stringify(initialJsonValue, null, 2)
  );
  const [result, setResult] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [jsonValidationError, setJsonValidationError] = useState<string | null>(
    null
  );

  useEffect(() => {
    let context = {};

    // Clear JSON input validation errors if there is no JSON value
    if (!jsonValue) {
      setJsonValidationError(null);
    }

    // Validate JSON input
    if (jsonValue) {
      try {
        context = JSON.parse(jsonValue);
        setJsonValidationError(null);
      } catch (e: any) {
        setJsonValidationError(e?.message);
      }
    }

    // If either of the inputs are empty, don't translate
    if (!handlebarsValue || !jsonValue) {
      setResult(null);
      setError(null);
      return;
    }

    translateMaps(handlebarsValue, context || {}, (err, res) => {
      setError(err || null);
      setResult(res || null);
    });
  }, [handlebarsValue, jsonValue]);

  const handlebarsInputRef = useRef<ReactCodeMirrorRef | null>(null);
  const jsonInputRef = useRef<ReactCodeMirrorRef | null>(null);

  const createLabelClickHandler =
    (codeMirrorRef: MutableRefObject<ReactCodeMirrorRef | null>) => () => {
      if (codeMirrorRef.current?.view) {
        codeMirrorRef.current.view.focus();
      }
    };

  return (
    <>
      <Heading
        title={t('adminDashboard:handlebarMapper.name')}
        subTitle={t('adminDashboard:handlebarMapper.description')}
        pageTitle={t('adminDashboard:handlebarMapper.name')}
      />
      <PageSection
        sx={{
          gap: 4
        }}
      >
        <InputSection>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Typography
              id="handlebars-input-label"
              variant="subtitle1"
              component="label"
              sx={{ cursor: 'default' }}
              htmlFor="handlebars-input"
              onClick={createLabelClickHandler(handlebarsInputRef)}
            >
              {t('admin:handlebarMapper.handlebarsInputLabel')}
            </Typography>
            <ExpressionExampleButton />
          </Box>
          <InputWrapper>
            <CodeMirror
              ref={handlebarsInputRef}
              id="handlebars-input"
              placeholder="This is a {{test}}"
              value={handlebarsValue}
              extensions={[EditorView.lineWrapping]}
              onChange={value => setHandlebarsValue(value)}
              height="auto"
            />
          </InputWrapper>
        </InputSection>
        <InputSection>
          <Typography
            id="json-input-label"
            variant="subtitle1"
            component="label"
            sx={{ cursor: 'default' }}
            htmlFor="json-input"
            onClick={createLabelClickHandler(jsonInputRef)}
          >
            {t('admin:handlebarMapper.jsonInputLabel')}
          </Typography>
          <InputWrapper>
            <CodeMirror
              ref={jsonInputRef}
              id="json-input"
              placeholder='{"test": "test"}'
              value={jsonValue}
              extensions={[json()]} // JSON syntax highlighting
              onChange={value => {
                setJsonValue(value);
              }}
              minHeight="200px"
              maxHeight="400px"
            />
          </InputWrapper>
          {jsonValidationError && (
            <FormHelperText error>{jsonValidationError}</FormHelperText>
          )}
        </InputSection>
      </PageSection>
      {error && <Alert severity="error">{error}</Alert>}
      <PageSection
        sx={{
          gap: 2
        }}
      >
        <Typography variant="subtitle1">
          {t('admin:handlebarMapper.result')}
        </Typography>
        <Divider />
        <Typography color="textSecondary">{result}</Typography>
      </PageSection>
    </>
  );
};

export default HandlebarMapper;
