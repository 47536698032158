import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

interface Option {
  name: string;
  value: string;
}

interface GetShareRulesInputsParams {
  resourceCollectionsOptions: Option[];
  resourceItemOptions: Option[];
}

export const getShareRuleInputs = ({
  resourceCollectionsOptions,
  resourceItemOptions
}: GetShareRulesInputsParams) => [
  {
    name: 'resourceCollectionId',
    displayName: 'Resource Collections',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        options: resourceCollectionsOptions,
        isAutocomplete: true
      }
    }
  },
  {
    name: 'resourceItemId',
    displayName: `Resource Items ${resourceItemOptions?.length ? '' : 'Empty'}`,
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        options: resourceItemOptions,
        isAutocomplete: true
      }
    }
  }
];
