import { useMemo } from 'react';
import { flow, find } from 'lodash';
import { useWatch, useFormContext, FieldErrors } from 'react-hook-form';
import { graphql } from '@apollo/client/react/hoc';

import { t } from 'i18next';

import { Box, Grid } from '@mui/material';

import { AppSettingsContextValue } from 'src/AppSettings';

import { scheduleTypes } from 'src/pages/Program/Constants';
import ProgramStepFooter, {
  SetSkipStep,
  SkipStep
} from 'src/pages/Program/ProgramStepFooter';

import {
  Architecture,
  Offer,
  OfferBillingMethod
} from 'src/generated/gql/graphql';

import { BILLING_METHODS } from 'src/common/paymentUtils';

import usePromoCode from 'src/pages/Program/ProgramSteps/PriceSummary/usePromoCode';
import Loading from 'src/components/Loading';
import { FormSection } from 'src/components/ReduxForm';
import PriceSummary from 'src/pages/Program/ProgramSteps/PriceSummary';

import { Features } from '../Feature/constants';
import { getPaymentMethods } from './queries';

import { addPaymentMethod } from './mutations';
import Offers from './Offers';
import useAddCreditCard from './useCreditCard';

interface InjectedProps {
  appSettings: AppSettingsContextValue;
}

interface FormData {
  spendStep: {
    scheduleType: string;
    billingMethod: string;
    oneTimeSpend: number;
    subscriptionSpend: number;
    subscription: string;
    offerId: string;
    promoCodes: any;
    stripeSourceId: string;
    paymentMethodId: string;
  };
}

interface CheckoutProps extends InjectedProps {
  architecture: Architecture;
  paymentMethods: any;
  formSyncErrors: FieldErrors;
  selectedBlueprint: any;
  isAutomated: boolean;
  spendAmount: number;
  minSpendLoading: boolean;
  hasMinSpendError: boolean;
  billingMethod: OfferBillingMethod;
  features: Features;
  addPaymentMutation: any;
  promoCodes: any;
  client: any;
  offersBySelectedType: Offer[];
  formName: string;
  offerId: string;
  skipStep: SkipStep | null;
  setSkipStep: SetSkipStep;
  type: string;
  submitForm: () => void;
  handleNext: () => void;
  lockSubmit: boolean;
  showValidationErrors: () => void;
  selectedBusinessObjects: any;
  hookFormContext?: any;
  formValues: FormData;
}

const pageText = () => ({
  billingSectionTitle: t('programCreate:Checkout.billingSectionTitle'),
  billingSectionDescription: t(
    'programCreate:Checkout.billingSectionDescription'
  ),
  PARTNER_INVOICE: t('programCreate:Checkout.billingMethodInvoice'),
  USER_CREDIT_CARD: t('programCreate:Checkout.billingMethodCreditCard')
});

const Checkout = (props: CheckoutProps) => {
  const {
    architecture,
    paymentMethods = [],
    selectedBlueprint,
    isAutomated,
    minSpendLoading,
    hasMinSpendError,
    skipStep,
    setSkipStep,
    type,
    submitForm,
    handleNext,
    lockSubmit,
    showValidationErrors,
    selectedBusinessObjects
  } = props;

  const { loading, error, paymentMethod = [] } = paymentMethods;
  const {
    formState: { errors }
  } = useFormContext();

  const scheduleType = useWatch({
    name: 'spendStep.scheduleType',
    defaultValue: ''
  });

  const isSubscription = scheduleType === scheduleTypes.subscription.value;

  const billingMethod = useWatch({
    name: 'spendStep.billingMethod',
    defaultValue: ''
  });

  let spendAmount = useWatch({
    name: 'spendStep.oneTimeSpend',
    defaultValue: ''
  });
  const subscriptionSpend = useWatch({
    name: 'spendStep.subscriptionSpend',
    defaultValue: ''
  });
  const subscription = useWatch({
    name: 'spendStep.subscription',
    defaultValue: ''
  });

  if (isSubscription) {
    if (billingMethod === BILLING_METHODS.partnerInvoice) {
      spendAmount = subscriptionSpend;
    }

    if (billingMethod === BILLING_METHODS.card) {
      const selectedSubscriptionPlan = find(
        selectedBlueprint?.paymentPlans?.subscriptionOffers,
        {
          billingMethod
        }
      );

      spendAmount = find(selectedSubscriptionPlan?.stripeSubscriptionPlans, {
        id: subscription
      })?.amount;
    }
  }

  const promoCodes = useWatch({
    name: 'spendStep.promoCodes',
    defaultValue: []
  });

  const isCardOffer = billingMethod === BILLING_METHODS.card;

  const text = useMemo(() => pageText(), []);

  const { validatePromoCode } = usePromoCode({
    spendAmount,
    isAutomated,
    promoCodes
  });

  const {
    updatingPayment,
    handleAddCard,
    stripeLoading // TODO: need to make some context so this updates everywhere
  } = useAddCreditCard();

  const handleNextWithValidation = async (
    successCallback: () => void,
    errorCallback?: () => void
  ) => {
    const { paymentMethod = [] } = paymentMethods;
    const allPaymentMethods = paymentMethod || [];

    if (isCardOffer && !allPaymentMethods.length) {
      // we try to submit the card field
      const addCardResponse: any = await handleAddCard(!!errorCallback);
      if (addCardResponse instanceof Error || addCardResponse?.error) {
        if (errorCallback) {
          errorCallback();
        }
        return;
      }
    }

    if (promoCodes && promoCodes.length) {
      // we assume only one promocode :/
      try {
        await validatePromoCode(promoCodes[0]?.promoCode);
      } catch (error) {
        if (errorCallback) {
          errorCallback();
        }
        return;
      }
    }

    if (successCallback) {
      successCallback();
    }
  };

  const allPaymentMethods = paymentMethod;

  const waitingOnStripe =
    allPaymentMethods.length > 0 && isCardOffer ? false : stripeLoading;

  if (loading || error) {
    return <Loading error={error} />;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormSection
            title={text.billingSectionTitle}
            description={text.billingSectionDescription}
          >
            <Box sx={{ pb: 1.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Offers selectedBlueprint={selectedBlueprint} />
                </Grid>
              </Grid>
            </Box>
          </FormSection>
        </Grid>
        <Grid item xs={12}>
          <PriceSummary
            selectedBlueprint={selectedBlueprint}
            isAutomated={isAutomated}
          />
        </Grid>
      </Grid>
      <ProgramStepFooter
        handleNextWithValidation={handleNextWithValidation}
        submitting={updatingPayment}
        formStepErrors={errors}
        submitDisabled={hasMinSpendError}
        loading={(isCardOffer && waitingOnStripe) || minSpendLoading}
        skipStep={skipStep}
        setSkipStep={setSkipStep}
        blueprint={selectedBlueprint}
        architecture={architecture}
        type={type}
        submitForm={submitForm}
        handleNext={handleNext}
        lockSubmit={lockSubmit}
        showValidationErrors={showValidationErrors}
        selectedBusinessObjects={selectedBusinessObjects}
      />
    </>
  );
};

export default flow(
  graphql(addPaymentMethod, {
    name: 'addPaymentMutation'
  }),
  graphql(getPaymentMethods, {
    name: 'paymentMethods'
  })
)(Checkout);
