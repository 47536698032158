import { Box } from '@mui/material';
import { map } from 'lodash';
import { useEffect, useRef } from 'react';
import { FacebookAudienceAccountSharingRuleModel } from 'src/generated/gql/graphql';

export interface SharingRule extends FacebookAudienceAccountSharingRuleModel {
  isExpanded?: boolean;
  toggleExpand: (id: string) => void;
}

interface ShareRuleValuesCellProps {
  row: SharingRule;
  expandedRows: Record<string, boolean>;
  setIsOverflowing: (isOverflowing: boolean) => void;
}

const ShareRuleValuesCell = ({
  row,
  expandedRows,
  setIsOverflowing
}: ShareRuleValuesCellProps) => {
  const { id } = row;
  const isExpanded = expandedRows[id] || false;

  const singleItem = row?.resourceItem;
  // If resourceItem exists, we only want to show the value of the resourceItem.
  // If not, the collection is tied to the sharing rule, display all values
  const values = singleItem
    ? [singleItem?.value]
    : map(row?.resourceCollection?.resources || [], 'value');

  const valuesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (valuesContainerRef.current) {
      const { scrollWidth, clientWidth } = valuesContainerRef.current;
      setIsOverflowing(scrollWidth > clientWidth);
    }
  }, [values, isExpanded]);

  return (
    <Box
      ref={valuesContainerRef}
      sx={{
        p: theme => (isExpanded ? `${theme.spacing(2)} 0` : 0),
        whiteSpace: isExpanded ? 'normal' : 'nowrap',
        overflow: isExpanded ? 'visible' : 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {values.join(', ')}
    </Box>
  );
};

export default ShareRuleValuesCell;
