import { t } from 'i18next';

import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow
  // Tooltip
} from '@mui/material';
import { styled } from '@mui/system';
// import InfoIcon from '@mui/icons-material/Info';

// import ExpandableText from 'src/pages/Leads/ExpandableText';
// import { Row } from 'src/pages/Leads/LeadsTable';

import { extractLeadQuestions } from './helpers';

const LeftColumnTypography = styled(Typography)(props => ({
  color: props.theme.palette.grey[600]
}));

const TableCellStyled = styled(TableCell)(props => ({
  border: '1px solid',
  borderColor: props.theme.palette.grey[300]
}));

export interface LeadField {
  [key: string]: string;
}

const LeadTableDetailPanel = ({ row }: { row: LeadField }) => {
  const leadQuestions = extractLeadQuestions(row);

  return (
    <Box sx={{ p: 4, pb: 2 }}>
      <Grid
        container
        spacing={2}
        sx={{ border: '1px solid', borderColor: 'grey.300', p: 2 }}
      >
        <Grid
          item
          // sm={6}
          sx={{
            pt: '0 !important',
            pl: '0 !important',
            pr: 2,
            borderRight: '1px solid',
            borderColor: 'grey.300'
          }}
        >
          <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t('leads:detailPanel.additionalInfo')}
          </Typography>
          <Table>
            <TableBody>
              {leadQuestions.map(({ name, value }) => {
                return (
                  <TableRow key={`${row.key}-${name}-${value}`}>
                    <TableCellStyled>
                      <LeftColumnTypography variant="body2">
                        {name}
                      </LeftColumnTypography>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {value}
                      </Typography>
                    </TableCellStyled>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        {/* // TODO: wire up to api data */}
        {/* {<Grid
          item
          sm={6}
          sx={{
            pt: '0 !important'
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mb: 2,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {t('leads:detailPanel.disclaimerConsent')}
            <Tooltip title={t('leads:detailPanel.disclaimerTip')}>
              <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
            </Tooltip>
          </Typography>

          <ExpandableText
            text="Wat Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing end"
            hasAccepted
            fullName={row.fullName}
          />
          <ExpandableText
            text="WAT Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing end"
            hasAccepted={false}
            fullName={row.fullName}
          />
        </Grid>} */}
      </Grid>
    </Box>
  );
};

export default LeadTableDetailPanel;
