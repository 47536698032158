import { useState } from 'react';
import { isEmpty, get, set } from 'lodash';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';

import { IconButton, Box } from '@mui/material';
import {
  DataGridPro,
  GridAlignment,
  GridRenderCellParams,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridApi,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useAppSettings } from 'src/AppSettings';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { getDemoLeads } from 'src/common/demoUser/helpers';
import formatters from 'src/common/formatters';
import { dayjs } from 'src/common/dates';

import { getLeads } from 'src/pages/Leads/queries';
import {
  extractLeadQuestions,
  formatRowDataEdge
} from 'src/pages/Leads/helpers';
import LeadsExport from 'src/pages/Leads/LeadsExport';
import LeadTableDetailPanel from 'src/pages/Leads/LeadTableDetailPanel';

export interface Row {
  leadId: string;
  key: string;
  architectureId: string;
  programId: string;
  orderId: string;
  date: string;
  name?: string;
  email?: string;
  phone_number?: string;
}

export interface LeadFieldFlattened {
  name?: string;
  email?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
}

const PAGE_SIZE = 25;

interface LeadsTableProps {
  programId?: string;
  noRowsPlaceholderComponent?: JSX.Element;
  pageSize?: number;
}

const LeadsTable = ({
  pageSize = PAGE_SIZE,
  programId,
  noRowsPlaceholderComponent
}: LeadsTableProps) => {
  const globalContext = useGlobalContext();
  const appSettings = useAppSettings();

  const userId = globalContext?.me?.id || '';
  const userEmail = globalContext?.me?.email || '';

  const { data, loading, fetchMore } = useQuery(getLeads, {
    variables: {
      ...(programId && { programId }),
      first: pageSize
    }
  });

  const userLeadsDemoOverrides = getDemoLeads({
    userId,
    userEmail,
    appSettings
  });
  // table rows
  const rowsFromData = data?.userLeads?.edges || [];
  const rowsWithDemoOverrides = formatRowDataEdge([
    ...userLeadsDemoOverrides,
    ...rowsFromData
  ]);

  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(null);
  const onRowsScrollEnd = () => {
    if (!loading && !isFetching && !isError) {
      fetchMore({
        variables: {
          endCursor: data?.userLeads?.pageInfo?.endCursor
        },
        updateQuery: (prev, result) => {
          const { fetchMoreResult } = result;
          const hasResults =
            (fetchMoreResult?.userLeads?.edges?.length || 0) > 0;

          // Note: this all gets run through the graphql props below so we only need to
          //       merge in the new edges. By merging the old data into the new it will keep
          //       the cursor correct so we can keep adding items.
          if (!hasResults) return prev;

          const mergePath = 'userLeads.edges';

          const newResult = fetchMoreResult;
          const oldData = get(prev, mergePath) || [];
          const newData = get(fetchMoreResult, mergePath) || [];

          set(newResult, mergePath, [...oldData, ...newData]);

          return newResult;
        }
      })
        .then(() => {
          setIsFetching(false);
        })
        .catch(e => {
          setIsError(e);
        });
    }
  };

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);

  const handleDetailPanelExpandedRowIdsChange = (newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds);
  };

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const columns: GridColDef[] = [
    {
      headerName: t('leads:leadsTable.nameTh'),
      field: 'name',
      align: 'left' as GridAlignment,
      headerAlign: 'left' as GridAlignment,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      type: 'string',
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row, id, api } = params;

        const hasAdditionalInfo = extractLeadQuestions(row);

        return (
          <>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                (api as GridApi).toggleDetailPanel(params.id);
              }}
              disabled={hasAdditionalInfo.length === 0}
              sx={{ opacity: hasAdditionalInfo.length === 0 ? 0 : 1 }}
            >
              {detailPanelExpandedRowIds.includes(id) ? (
                <ExpandLessIcon color="action" />
              ) : (
                <ExpandMoreIcon color="action" />
              )}
            </IconButton>
            {row.name || '-'}
          </>
        );
      }
    },
    {
      headerName: t('leads:leadsTable.emailTh'),
      field: 'email',
      align: 'left' as GridAlignment,
      headerAlign: 'left' as GridAlignment,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      type: 'string',
      flex: 1,
      minWidth: 200
    },
    {
      headerName: t('leads:leadsTable.phoneTh'),
      field: 'phone',
      align: 'left' as GridAlignment,
      headerAlign: 'left' as GridAlignment,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      type: 'string',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return <>{row.phone ? formatters.PHONE(row.phone) : '-'}</>;
      }
    },
    {
      headerName: t('leads:leadsTable.dateTh'),
      field: 'date',
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      type: 'string',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return <>{row.date ? dayjs(row.date).format('MM/DD/YYYY') : '-'}</>;
      }
    }
    // TODO: add source column once API returns it (program name that links to the program)
  ];

  if (!loading && isEmpty(rowsWithDemoOverrides)) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {noRowsPlaceholderComponent || ''}
      </Box>
    );
  }

  return (
    <>
      <DataGridPro
        sx={{
          height: '100%',
          minHeight: '100px',
          padding: '0',
          width: '100%',
          '& .MuiDataGrid-virtualScroller': { minHeight: '50px' }
        }}
        // columns & rows
        columns={columns}
        rows={rowsWithDemoOverrides}
        getRowId={(row: Row) => {
          return row.leadId;
        }}
        // pagination
        onRowsScrollEnd={onRowsScrollEnd}
        scrollEndThreshold={80}
        // config
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false
            }
          }
        }}
        loading={loading}
        slots={{
          detailPanelExpandIcon: ExpandMoreIcon,
          detailPanelCollapseIcon: ExpandLessIcon,
          toolbar: () => (
            <Box>
              <LeadsExport
                programId={programId}
                rows={rowsWithDemoOverrides}
                selected={rowSelectionModel}
              />
            </Box>
          )
        }}
        // footer items
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={(params: GridRowParams) => {
          if (params.id && detailPanelExpandedRowIds.includes(params.id)) {
            return <LeadTableDetailPanel row={params.row} />;
          }
          return <Box key={params.id} />;
        }}
        keepNonExistentRowsSelected
        disableRowSelectionOnClick
        checkboxSelection
        rowSelection
        onRowSelectionModelChange={(
          newRowSelectionModel: GridRowSelectionModel
        ) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        edit={false}
      />
    </>
  );
};

export default LeadsTable;
