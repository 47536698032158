/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
  ServerParseError
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { get } from 'lodash';
import { print } from 'graphql/language/printer';
import isAuthError from 'src/Auth/isAuthError';
import apolloSentryLink from 'src/common/ApolloSentryLink';
import Logger from 'src/common/Logger';
import SentryUtil from 'src/common/SentryUtil';
import { onGraphqlError } from 'src/components/Admin/ErrorConsole/actions';
import { SentryWrappedError } from 'src/common/SentryWrappedError';
import { Store } from 'redux';
import { AppSettingsContextValue } from 'src/AppSettings';
import Auth from 'src/Auth/Auth';
import { __EnumValue, __Type } from 'src/generated/gql/graphql';
import { apolloInstrumentationLink } from './ApolloInstrumentationLink';

const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists.
  // Note: We pull the ev token first if it is set. For visitors, we pull
  //       the value form the standard access_token.
  const token = localStorage.getItem('ev_access_token');
  // return the headers to the context so httpLink can read them

  headers = headers || {}; // eslint-disable-line no-param-reassign

  if (token) {
    headers.authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
  }

  return { headers };
});

const IGNORE_MESSAGES = [
  "Variable 'externalIds' has coerced Null value for NonNull type",
  'Failed to fetch',
  'The Internet connection appears to be offline',
  'Cancelled',
  'cancelled',
  'Your card was declined.',
  new RegExp('Page.*is not visible', 'i'),
  'NetworkError'
];

// Some graphql errors we know will happen on the client-side and we are ok with
// that. The reason why we stop them here rather than setting them to ignore in
// Sentry is so that this type of error doesn't gobble up the breadcrumb trail
// left before a real issue possibly occurs.
const shouldIgnoreError = (
  gqlOrNwError: Error | ServerError | ServerParseError | string = ''
) => {
  const message = get(gqlOrNwError, 'message') || '';

  for (let i = 0; i < IGNORE_MESSAGES.length; i++) {
    if (
      typeof IGNORE_MESSAGES[i] === 'string' &&
      message.indexOf(IGNORE_MESSAGES[i] as string) !== -1
    ) {
      return true;
    }

    // This section will support regex matches for more complicated error
    // messages.
    if (
      IGNORE_MESSAGES[i] instanceof RegExp &&
      (IGNORE_MESSAGES[i] as RegExp).exec(message)
    ) {
      return true;
    }
  }

  return false;
};

interface IntrospectionParams {
  loading: boolean;
  error: Error;
  __type: __Type;
}

export const mapIntrospectionToProps = (
  { loading, error, __type }: IntrospectionParams,
  name: string
) => {
  let enumFields = __type?.fields?.reduce((accum, field) => {
    const ofType = field?.type?.ofType;
    if (ofType?.kind !== 'ENUM') {
      return accum;
    }
    return {
      ...accum,
      [ofType?.name as string]: ofType?.enumValues!.map((v: __EnumValue) => ({
        name: v.name,
        value: v.name
      }))
    };
  }, {});

  if (!enumFields) {
    enumFields = __type?.enumValues!.map((v: __EnumValue) => ({
      name: v.name,
      value: v.name
    }));
  }

  return {
    [name ? `${name}EnumFields` : 'enumFields']: enumFields,
    [name ? `${name}IntrospectionMeta` : 'introspectionMeta']: {
      loading,
      error
    }
  };
};

export interface ApolloClientOptions {
  auth: typeof Auth;
  reduxStore: Store;
  appSettings: AppSettingsContextValue;
}

export const catchApolloError = (err: ErrorResponse) => {
  const graphQLErrors = err?.graphQLErrors;
  const networkError = err?.networkError;

  if (networkError) {
    // This will catch issues like "failed to fetch" network errors
    // caused by the user closing their laptop, closing their
    // phone's browser, etc. These are non-issues that we don't want
    // to send to Sentry.
    if (shouldIgnoreError(networkError)) {
      return;
    }

    SentryUtil.addBreadcrumb({
      category: 'network',
      data: networkError
    });
  }

  if (Array.isArray(graphQLErrors)) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      const gqlError = graphQLErrors[i];
      if (shouldIgnoreError(gqlError)) {
        Logger.debug('ApolloUtil: ignoring gqlError: ', gqlError);
        return;
      }

      // Add extra error information to the breadcrumb trail.
      SentryUtil.addBreadcrumb({
        category: 'graphql',
        data: {
          graphQlErrorIndex: i,
          description: get(gqlError, 'description'),
          errorType: get(gqlError, 'errorType'),
          message: get(gqlError, 'message'),
          validationErrorType: get(gqlError, 'validationErrorType')
        }
      });
    }
  }

  // Now log the actual error as an event.
  // While we can have multiple errors, we'll just grab the first
  // error and use that one to represent the whole error.
  // Not perfect, but better than nothing and simple.
  const representativeError =
    graphQLErrors?.[0]?.message || 'missing gql error';

  const errorName = graphQLErrors?.[0]?.path?.[0] || 'unknown';

  const wrappedError = new SentryWrappedError(
    `Caught error in ApolloUtil: ${representativeError}`,
    `GraphQL error path: ${errorName}`,
    err
  );
  SentryUtil.captureException(wrappedError);
};

class ApolloUtil {
  static newClient(options: ApolloClientOptions) {
    if (!options || !options.auth) {
      throw new Error('No auth object provided.');
    }

    const errorLink = (store: Store) =>
      onError(err => {
        const graphQLErrors = err?.graphQLErrors;
        const networkError = err?.networkError;

        if (isAuthError(graphQLErrors, networkError)) {
          options.auth.login();
          return;
        }

        // print errors to the admin console for easier debugging
        const query = print(err.operation.query);
        store.dispatch(
          onGraphqlError({
            slug: `${
              err.operation.operationName
            } - ${new Date().toISOString()}`,
            query,
            variables: err.operation.variables,
            errorMessages: graphQLErrors
          })
        );

        catchApolloError(err);
      });

    const uploadLink = createUploadLink({
      uri: import.meta.env.EVOCALIZE_LITHIUM_API_URL
    });

    return new ApolloClient({
      connectToDevTools:
        get(options, 'appSettings.app.general.connectDevTools') === 'true',

      link: ApolloLink.from([
        errorLink(options.reduxStore),
        apolloSentryLink,
        apolloInstrumentationLink,
        authLink.concat(uploadLink)
      ]),
      cache: new InMemoryCache()
    });
  }
}

export default ApolloUtil;
