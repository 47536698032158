import { t } from 'i18next';
import { AppSettingsContextValue } from 'src/AppSettings';

export const getAppName = (appSettings: AppSettingsContextValue) => {
  return (
    appSettings?.app?.general?.appName || appSettings?.app?.organizationName
  );
};

export const getOfficeName = (appSettings: AppSettingsContextValue) => {
  return appSettings?.app?.general?.officeName || t('office.name');
};
