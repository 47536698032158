import React, { useState, useRef, useEffect } from 'react';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { useQuery } from '@apollo/client';
import { Box, Typography, IconButton } from '@mui/material';
import {
  DataGridPro,
  GridAlignment,
  GridRenderCellParams,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridApi,
  GRID_DETAIL_PANEL_TOGGLE_FIELD
} from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MlpStatus from 'src/components/Status/MlpStatus';
import { t } from 'i18next';
import { useDataTablePagination } from 'src/components/Pagination/hooks';
import { useHistory } from 'react-router-dom';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import ProgramsTable from 'src/pages/Programs/ProgramsTable';
import ToolBar from 'src/components/DataTable/Toolbar/Toolbar';

import { PROGRAM_TABLE_FIELDS } from 'src/pages/Programs/ProgramsTable/constants';
import ProgramNameColumn from 'src/pages/Programs/ProgramsTable/ProgramNameColumn';
import { getMlpParents } from '../queries';

const PAGE_SIZE = 20;

type MlpParentsTableProps = {
  mlpParentFilter: JSX.Element;
  setShowMlpFilter: (show: boolean) => void;
  showMlpOnly: boolean;
};

const MlpParentsTable = ({
  mlpParentFilter,
  setShowMlpFilter,
  showMlpOnly
}: MlpParentsTableProps) => {
  const { architectures, architectureNameById } = useGlobalContext();
  const history = useHistory();
  const panelRef = useRef<HTMLDivElement>(null);
  const { data, loading, refetch } = useQuery(getMlpParents, {
    variables: {
      first: PAGE_SIZE
    }
  });

  useEffect(() => {
    if (!loading && (data?.getMultiLocationPrograms?.edges?.length ?? 0) > 0) {
      setShowMlpFilter(true);
    }
  }, [data, loading]);

  const { paginationModel, rowCount, handlePaginationModelChange } =
    useDataTablePagination({
      pageInfo: data?.getMultiLocationPrograms?.pageInfo,
      resultsPerPage: PAGE_SIZE,
      resultsLength: data?.getMultiLocationPrograms?.edges?.length,
      refetch
    });

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);

  const handleDetailPanelExpandedRowIdsChange = (newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds);
  };

  const handleRowClick = (
    params: { row: { id: string; architectureId: string }; id: any },
    event: React.MouseEvent<HTMLElement>
  ) => {
    const { row } = params;

    const architectureId = row?.architectureId;
    const orderId = row?.id;

    const linkPath = generateLinkPath(paths.architecture.multiLocationProgram, {
      architectureId,
      orderId
    });

    // if cmd or ctrl key is pressed, open in new tab
    if (event?.metaKey) {
      // Note: we need to add the hash to the linkPath b/c generateLinkPath just returns the path
      return window.open(`#${linkPath}`, '_blank');
    }

    return history.push(linkPath);
  };

  const getDetailPanelHeight = () => {
    // this was a fine idea but the table doesn't load in time :(
    // if (panelRef.current) {
    //   return panelRef.current.offsetHeight;
    // }
    return 400;
  };

  const columns: GridColDef[] = [
    {
      align: 'center' as GridAlignment,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: PROGRAM_TABLE_FIELDS.status,
      filterable: false,
      headerAlign: 'center' as GridAlignment,
      headerName: t('programs:tableHeader.status'),
      hideable: false,
      sortable: false,
      maxWidth: 150,
      minWidth: 150,
      type: 'singleSelect',
      renderCell: (params: any) => {
        const { id, api } = params;
        return (
          <>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                (api as GridApi).toggleDetailPanel(params.id);
              }}
            >
              {detailPanelExpandedRowIds.includes(id) ? (
                <ExpandLessIcon color="action" />
              ) : (
                <ExpandMoreIcon color="action" />
              )}
            </IconButton>
            <MlpStatus
              status={params?.row?.status}
              tableView
              renderTextStatus
              hideHelpTooltip
            />
          </>
        );
      }
    },
    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: PROGRAM_TABLE_FIELDS.name,
      filterable: false,
      headerAlign: 'left',
      headerName: t('programs:tableHeader.name'),
      hideable: false,
      sortable: false,
      type: 'singleSelect',
      renderCell: ProgramNameColumn,
      minWidth: 150,
      flex: 1
    },
    {
      align: 'left',
      minWidth: 200,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: PROGRAM_TABLE_FIELDS.theme,
      filterable: true,
      headerAlign: 'left',
      headerName: t('programs:tableHeader.theme'),
      hideable: false,
      sortable: false,
      type: 'singleSelect',
      valueOptions: architectures
        ? architectures.map((architecture: any) => {
            return {
              label: architecture.name,
              value: architecture.id
            };
          })
        : [],
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          architectureNameById?.[row?.architectureId] ||
          architectureNameById?.[row?.architecture?.id] || // draft
          row?.architectureId ||
          row?.architecture?.id // draft
        );
      }
    },
    {
      align: 'center',
      minWidth: 200,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: 'numChildren',
      filterable: false,
      headerAlign: 'center',
      headerName: t('programs:tableHeader.numChildren'),
      sortable: false
    },
    {
      align: 'center',
      minWidth: 100,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: 'summedPriceAmount',
      filterable: false,
      headerAlign: 'center',
      headerName: t('programs:tableHeader.totalBudget'),
      hideable: false,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        return row?.summedPriceAmount ? `$${row?.summedPriceAmount}` : '-';
      }
    }
  ];
  const rowsFromData =
    data?.getMultiLocationPrograms?.edges?.map((edge: any) => edge.node) || [];

  if (!showMlpOnly) {
    return null;
  }

  return (
    <DataGridPro
      rows={rowsFromData}
      columns={columns}
      sx={{ width: '100%' }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false
          }
        }
      }}
      edit={false}
      rowSelection={false}
      pagination
      pageSizeOptions={[PAGE_SIZE]}
      rowCount={rowCount}
      paginationMode="server"
      onPaginationModelChange={handlePaginationModelChange}
      paginationModel={paginationModel}
      loading={loading}
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={(params: GridRowParams) => {
        if (params.id && detailPanelExpandedRowIds.includes(params.id)) {
          return (
            <Box ref={panelRef} sx={{ p: 2 }}>
              <Typography
                variant="body1"
                sx={{ mb: 2, ml: 6, fontWeight: 'bold' }}
              >
                {t('programs:headers.generatedPrograms')}
              </Typography>
              <ProgramsTable
                multiLocationProgramId={`${params.id}`}
                showDrafts={false}
                hideToolbar
              />
            </Box>
          );
        }
        return <Box key={params.id} />;
      }}
      slots={{
        detailPanelExpandIcon: ExpandMoreIcon,
        detailPanelCollapseIcon: ExpandLessIcon,
        toolbar: () => <ToolBar noColumns mlpParentFilter={mlpParentFilter} />
      }}
      onRowClick={handleRowClick}
    />
  );
};

export default MlpParentsTable;
