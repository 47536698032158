import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

import { IconButton, Menu, MenuItem, Box, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import useCopyToClipboard from 'src/hooks/useCopyToClipboard';

type ExpressionHelpers = {
  name: string;
  value: string;
};

const expressionsHelpers: ExpressionHelpers[] = [
  { name: 'CURRENCY', value: 'CURRENCY 12345.67' },
  { name: 'NUMBER', value: 'NUMBER 12345' },
  { name: 'FLOAT', value: 'FLOAT 12345.6789' },
  { name: 'DATE', value: 'DATE someDate' },
  { name: 'PHONE', value: 'PHONE "5551234567"' },
  { name: 'DEFAULT', value: 'DEFAULT someValue "default"' },
  {
    name: 'present',
    value: 'present someValue'
  },
  {
    name: 'exists',
    value: 'exists someValue'
  },
  {
    name: 'eq',
    value: 'eq value1 value2'
  },
  { name: 'FALLBACK', value: 'FALLBACK value fallback "default"' },
  { name: 'SPLIT_AND_TAKE', value: 'SPLIT_AND_TAKE someString "," 0' },
  { name: 'REPLACE', value: 'REPLACE someString "old" "new"' },
  { name: '{{if}}', value: '{{#if (someValue)}}true{{else}}false{{/if}}' },
  { name: '(or)', value: '(or condition1 condition2)' },
  { name: '(and)', value: '(and condition1 condition2)' },
  {
    name: 'contains',
    value: 'contains "haystack" "needle"'
  },
  {
    name: 'not_contains',
    value: 'not_contains "haystack" "needle"'
  },
  { name: 'PERCENT_ENCODE', value: 'PERCENT_ENCODE "string"' },
  { name: 'BASE_URL', value: 'BASE_URL "url"' },
  { name: 'SLUGIFY', value: 'SLUGIFY "slugify me"' },
  { name: 'TRIM', value: 'TRIM "string"' },
  { name: 'CLEANUP_URL', value: 'CLEANUP_URL "url"' }
];

const ExpressionExampleButton = () => {
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = async (text: string) => {
    await copy(text);
    handleClose();
    enqueueSnackbar(t('copyToClipboard:snackbar.success'), {
      variant: 'success'
    });
  };

  const sortedExpressions = sortBy(
    expressionsHelpers,
    (expr: ExpressionHelpers) => expr.name.toLowerCase()
  );

  return (
    <Box
      sx={{
        ml: 1
      }}
    >
      <Tooltip title={t('admin:handlebarMapper.expressionButtonTip')}>
        <IconButton onClick={handleClick} size="small">
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {sortedExpressions.map(({ name, value }: ExpressionHelpers) => (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <MenuItem key={name} onClick={() => handleCopy(value)}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ExpressionExampleButton;
