import {
  createContext,
  useContext,
  cloneElement,
  ComponentType,
  ReactNode
} from 'react';
import { isEmpty } from 'lodash';

import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useAppSettings, AppSettingsContextValue } from 'src/AppSettings';
import { allowListPaths } from 'src/routes/paths';
import {
  OrganizationPlgType,
  GetUserQuery,
  CheckForExistingActiveConnection
} from 'src/generated/gql/graphql';
import { EV_ORGANIZATION_ID } from 'src/Auth/common';

import { checkForExistingActiveConnection, getUser } from './queries';
import Auth from './Auth/Auth';

type AvailableGroup = GetUserQuery['me']['availableGroups'][number];

export interface OfficeInterface {
  officeName: string;
  selectedOffice: AvailableGroup;
  isTeamsEnabled: boolean;
  id?: string;
  offices: AvailableGroup[];
}

export interface GlobalContextType {
  me: GetUserQuery['me'] | undefined;
  us: GetUserQuery['us'] | undefined;
  myOrganization: GetUserQuery['myOrganization'];
  appPermissions: {
    isEvocalizeOrg: boolean;
  };
  architectures: GetUserQuery['us']['architectures'];
  architectureNameById: Record<string, string>;
  office: OfficeInterface;
  isAllowListPath: boolean;
  loading: boolean;
  refetch: (options?: any) => void;
  error: any;
  orgTypes: {
    isPlg: boolean;
    isWorkatoConnect: boolean;
  };
  workatoConnection?: CheckForExistingActiveConnection;
}

export const GlobalContext = createContext<GlobalContextType>({
  me: undefined,
  us: undefined,
  myOrganization: undefined,
  appPermissions: {
    isEvocalizeOrg: false
  },
  architectures: [],
  architectureNameById: {},
  office: {
    officeName: '',
    selectedOffice: {} as AvailableGroup,
    isTeamsEnabled: false,
    offices: []
  },
  isAllowListPath: false,
  loading: false,
  refetch: () => {},
  error: null,
  orgTypes: {
    isPlg: false,
    isWorkatoConnect: false
  },
  workatoConnection: undefined
});

export const withGlobalContext = <P extends object>(
  Component: ComponentType<P>
) => {
  return (props: P) => {
    const settings = useContext(GlobalContext);
    return <Component {...props} globalContext={settings} />;
  };
};

export const useGlobalContext = () => useContext(GlobalContext);

export const getIsTeamsEnabled = (role: string | undefined): boolean => {
  return !!role?.toLowerCase()?.includes('team');
};

const getOrgTypes = (appSettings: AppSettingsContextValue) => {
  const plgConfig = appSettings?.organizationPlgConfig;
  const isPlg = !!plgConfig;

  const isWorkatoConnect =
    plgConfig?.type === OrganizationPlgType.MortgageTotalExpert;

  return {
    isPlg,
    isWorkatoConnect
  };
};

const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const appSettings = useAppSettings();
  const { pathname } = useLocation();

  const {
    loading,
    error,
    refetch,
    data: userData
  } = useQuery(getUser, {
    variables: {
      ...(appSettings?.app?.features?.showInactiveBlueprints && {
        showInactive: true
      })
    },
    skip:
      allowListPaths.filter(url => pathname === url).length > 0 ||
      !Auth.isAuthenticated()
  });

  const orgTypes = getOrgTypes(appSettings);

  const checkForWorkatoConnectionResult = useQuery(
    checkForExistingActiveConnection,
    {
      fetchPolicy: 'no-cache',
      skip: !orgTypes.isWorkatoConnect || isEmpty(userData?.me)
    }
  );

  const workatoConnection =
    checkForWorkatoConnectionResult?.data?.checkForExistingActiveConnection;

  const offices = (userData?.me?.availableGroups || [])?.map(group => ({
    ...group,
    isTeamsEnabled: getIsTeamsEnabled(userData?.us?.role)
  }));
  const officeId = userData?.us?.id || undefined;
  const selectedOffice =
    offices?.find(obj => obj.id === officeId) || ({} as any);
  const officeName = selectedOffice?.name || 'office';
  const isTeamsEnabled = getIsTeamsEnabled(userData?.us?.role);

  const appPermissions = {
    isEvocalizeOrg: userData?.myOrganization?.id === EV_ORGANIZATION_ID
  };

  // The options parameter being passed to the getUser refetch method has been added
  // to be backward compatible with the previous implementation of the refetch method
  // which called the getUser refetch directly instead of a combined refetch function.
  const refetchUser = (options: any) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch(options);

    if (orgTypes.isWorkatoConnect) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      checkForWorkatoConnectionResult.refetch();
    }
  };

  const globalContext = {
    me: userData?.me,
    us: userData?.us,
    myOrganization: userData?.myOrganization,
    appPermissions,
    architectures: userData?.us?.architectures || [],
    architectureNameById: userData?.us?.architectures
      ? userData?.us?.architectures?.reduce((acc, architecture) => {
          return { ...acc, [architecture.id]: architecture.name };
        }, {})
      : {},
    office: {
      id: officeId,
      offices,
      officeName,
      selectedOffice,
      isTeamsEnabled
    },
    isAllowListPath: allowListPaths.includes(pathname),
    loading,
    refetch: refetchUser,
    error: error || checkForWorkatoConnectionResult.error,
    orgTypes,
    ...(orgTypes.isWorkatoConnect && { workatoConnection })
  };

  return (
    <GlobalContext.Provider value={globalContext}>
      {cloneElement(children as React.ReactElement, {
        globalContext
      })}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
