import { Box, Button, Checkbox, Divider, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useBell, useNotificationPreferences } from '@magicbell/react-headless';
import SentryUtil from 'src/common/SentryUtil';
import { useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { t } from 'i18next';
import { styled, Theme } from '@mui/system';
import {
  CategoryChannelPreference,
  ChannelPreference
} from '@magicbell/react-headless/src/types/IRemoteNotificationPreferences';
import { isEqual, isNil } from 'lodash';
import Loading from 'src/components/Loading';
import { FlexExpander } from 'src/components/Styling/FlexExpander';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  getEmailIdentityId,
  fetchEmailNotificationAsset
} from 'src/pages/Admin/NotificationPreferences/queries';

// these are pulled from magicbell
enum NotificationChannels {
  InApp = 'in_app',
  MobilePush = 'mobile_push',
  WebPush = 'web_push',
  Slack = 'slack',
  SMS = 'sms',
  Email = 'email'
}

const activeChannels = new Set([
  NotificationChannels.InApp,
  NotificationChannels.Email
]);
const activeChannelArray = Array.from(activeChannels);

const isChannelActive = (
  channel: ChannelPreference,
  isEmailEnabled = false
) => {
  if (channel.slug === NotificationChannels.Email) {
    return isEmailEnabled;
  }

  return activeChannels.has(channel.slug as NotificationChannels);
};

const CategoryWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2)
}));

const CategoryLabel = styled(Typography)(() => ({
  flex: 2
}));

const CategoryChannelBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1
}));

const NotificationSection = styled(Box)(() => ({
  display: 'flex'
}));

const NotificationSectionLeftBar = styled(Box)(({ theme }) => ({
  width: '24px',
  marginRight: theme.spacing(2)
}));

const NotificationSectionRight = styled(Box)(() => ({
  flex: '1'
}));

const computeUnsubscribedFromAllState = (
  categories: CategoryChannelPreference[],
  isEmailEnabled = false
) => {
  if (categories.length === 0) {
    return [];
  }

  // First build up our list of channels using the first one to start with
  const channels = categories[0].channels
    .filter(channel => isChannelActive(channel, isEmailEnabled))
    .map(channel => ({
      slug: channel.slug,
      enabled: channel.enabled
    }));

  // Then go through the channels and see if they are ALL enabled or not
  for (let i = 0; i < channels.length; i++) {
    channels[i].enabled = categories.every(
      category =>
        category.channels.find(c => c.slug === channels[i].slug)?.enabled ===
        false
    );
  }
  return channels;
};

const getChannelLabel = (channel: NotificationChannels) => {
  switch (channel) {
    case NotificationChannels.Email:
      return t('notifications:preferences.channel.email');
    case NotificationChannels.InApp:
      return t('notifications:preferences.channel.inApp');
    case NotificationChannels.MobilePush:
    case NotificationChannels.WebPush:
    case NotificationChannels.Slack:
    case NotificationChannels.SMS:
    default:
      // If we don't know/support the channel, just return the plain name
      return channel;
  }
};

const getCategoryLabel = (category: CategoryChannelPreference) => {
  // This requires some manual updates, but magicbell doesn't provide a localized
  // label so this is as good as we're gonna get.
  // Make sure this function gracefully handles any new categories that come in
  // by passing them through (and then updating the function!)
  switch (category.slug) {
    case 'program-ending':
      return t('notifications:preferences.category.programEnding');
    case 'program-issues':
      return t('notifications:preferences.category.programIssues');
    case 'program-lifecycle':
      return t('notifications:preferences.category.programLifecycle');
    default:
      return category.label;
  }
};

export const NotificationPreferences = () => {
  const globalContext = useGlobalContext();
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState<CategoryChannelPreference[]>([]);
  const [pendingSave, setPendingSave] = useState(false);
  const {
    fetch,
    save,
    categories: originalCategories,
    lastFetchedAt
  } = useNotificationPreferences();
  const bell = useBell();

  const { data } = useQuery(fetchEmailNotificationAsset, {
    variables: {
      organizationId: globalContext?.me?.organizationId || ''
    }
  });

  const { data: emailIdentityData } = useQuery(getEmailIdentityId, {
    variables: {
      organizationId: globalContext?.me?.organizationId || ''
    }
  });

  const emailIdentity = emailIdentityData?.fetchEmailIdentity;
  const emailNotificationAsset = data?.fetchEmailNotificationAsset;

  // if we have emailIdentity and emailNotificationAsset email is "setup" so we can show the preferences
  const isEmailEnabled =
    !isNil(emailIdentity) && !isNil(emailNotificationAsset);

  useEffect(() => {
    // Don't fetch preferences until we've finished fetching magicbell config
    if (bell != null && originalCategories.length === 0) {
      fetch().catch(error => {
        SentryUtil.captureException(error);
      });
    }
  }, [bell, categories, fetch, originalCategories.length]);

  useEffect(() => {
    const filteredCategories = originalCategories.filter(category => {
      return !category.slug.includes('discarded_at');
    });

    setCategories(filteredCategories);
  }, [originalCategories]);

  const unsubscribedFrom = computeUnsubscribedFromAllState(
    categories,
    isEmailEnabled
  );

  const updateCategoryChannelPreference = (
    categorySlug: string,
    channelSlug: string,
    enabled: boolean
  ) => {
    const newCategories = categories.map(category => {
      if (category.slug === categorySlug) {
        return {
          ...category,
          channels: category.channels.map(channel => {
            if (channel.slug === channelSlug) {
              return {
                ...channel,
                enabled
              };
            }
            return channel;
          })
        };
      }
      return category;
    });
    setCategories(newCategories);
  };

  const updateAllChannelPreferences = (
    channelSlug: string,
    enabled: boolean
  ) => {
    const newCategories = categories.map(category => {
      return {
        ...category,
        channels: category.channels.map(channel => {
          if (channel.slug === channelSlug) {
            return {
              ...channel,
              enabled
            };
          }
          return channel;
        })
      };
    });
    setCategories(newCategories);
  };

  const haveModifiedPreferences = !isEqual(categories, originalCategories);

  if (bell == null || lastFetchedAt == null) {
    return <Loading />;
  }

  return (
    <Box sx={{ maxWidth: '750px' }}>
      <NotificationSection>
        <NotificationSectionLeftBar>
          <EmailIcon />
        </NotificationSectionLeftBar>
        <NotificationSectionRight>
          <Typography
            variant="body1"
            sx={{ marginBottom: theme => theme.spacing(1), fontWeight: 'bold' }}
          >
            {t('notifications:preferences.emailHeader')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: theme => theme.palette.text.secondary }}
          >
            {globalContext?.me?.email ?? ''}
          </Typography>
        </NotificationSectionRight>
      </NotificationSection>
      <NotificationSection
        sx={{ marginTop: (theme: Theme) => theme.spacing(3) }}
      >
        <NotificationSectionLeftBar>
          <NotificationsIcon />
        </NotificationSectionLeftBar>
        <NotificationSectionRight>
          <Typography
            variant="body1"
            sx={{ marginBottom: theme => theme.spacing(1), fontWeight: 'bold' }}
          >
            {t('notifications:preferences.channelHeader')}
          </Typography>
          <CategoryWrapper>
            <CategoryLabel />
            {activeChannelArray
              .filter(channel => {
                if (channel === NotificationChannels.Email) {
                  return isEmailEnabled;
                }
                return true;
              })
              .map(channel => {
                return (
                  <CategoryChannelBox key={channel}>
                    <Typography>{getChannelLabel(channel)}</Typography>
                  </CategoryChannelBox>
                );
              })}
          </CategoryWrapper>
          {categories.map(category => {
            return (
              <CategoryWrapper key={category.slug}>
                <CategoryLabel>{getCategoryLabel(category)}</CategoryLabel>
                {category.channels
                  .filter(channel => isChannelActive(channel, isEmailEnabled))
                  .map(channel => {
                    return (
                      <CategoryChannelBox key={channel.slug}>
                        <Checkbox
                          data-cy={`${channel.slug}-notification-checkbox-${channel.slug}`}
                          checked={channel.enabled}
                          onChange={e => {
                            updateCategoryChannelPreference(
                              category.slug,
                              channel.slug,
                              e.target.checked
                            );
                          }}
                        />
                      </CategoryChannelBox>
                    );
                  })}
              </CategoryWrapper>
            );
          })}
          <Divider sx={{ marginTop: theme => theme.spacing(2) }} />
          <CategoryWrapper>
            <CategoryLabel>
              {t('notifications:preferences.unsubscribeAll')}
            </CategoryLabel>
            {unsubscribedFrom.map(channel => {
              return (
                <CategoryChannelBox key={channel.slug}>
                  <Checkbox
                    data-cy={`${channel.slug}-notification-checkbox-unsubscribeAll`}
                    checked={channel.enabled}
                    onChange={e => {
                      updateAllChannelPreferences(
                        channel.slug,
                        // Negate the checked since if this is checked (true) all
                        // channels should be unsubscribed (false)
                        !e.target.checked
                      );
                    }}
                  />
                </CategoryChannelBox>
              );
            })}
          </CategoryWrapper>
        </NotificationSectionRight>
      </NotificationSection>
      <Box sx={{ display: 'flex', marginTop: theme => theme.spacing(2) }}>
        <FlexExpander />
        <Button
          disabled={!haveModifiedPreferences || pendingSave}
          onClick={() => {
            setCategories(originalCategories);
          }}
        >
          {t('notifications:preferences.cancel')}
        </Button>
        <LoadingButton
          data-cy="save-notification-preferences-button"
          variant="contained"
          disabled={!haveModifiedPreferences}
          loading={pendingSave}
          onClick={() => {
            setPendingSave(true);
            save({ categories })
              .then(() => {
                enqueueSnackbar(t('notifications:preferences.saveSuccess'), {
                  variant: 'success'
                });
              })
              .catch(error => {
                SentryUtil.captureException(error);
                enqueueSnackbar(t('notifications:preferences.saveError'), {
                  variant: 'error'
                });
              })
              .finally(() => {
                setPendingSave(false);
              });
          }}
        >
          {t('notifications:preferences.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};
