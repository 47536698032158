import { demoUserIds } from 'src/common/demoUser/demoUsers';
import { demoUserData } from 'src/common/demoUser/demoUserData';

import { AppSettingsContextValue } from 'src/AppSettings';

export const insightTypes = {
  dashboard: 'dashboard',
  program: 'program'
} as const;

type InsightType = (typeof insightTypes)[keyof typeof insightTypes];

interface GetDemoInsightsParams {
  userId: string;
  userEmail: string;
  appSettings: AppSettingsContextValue;
  kpis: { slug: string }[];
  type: InsightType;
}

const demoUserEmail = 'tech+demo@evocalize.com';

export const getDemoInsights = ({
  userId,
  userEmail,
  appSettings,
  kpis,
  type
}: GetDemoInsightsParams) => {
  const localOverrideInsights =
    appSettings?.app?.demoOverrides?.programInsights;

  let demoInsights = null;

  // Demo overrides for Demo Users.
  if (demoUserIds[userId] || userEmail === demoUserEmail) {
    demoInsights =
      type === insightTypes.dashboard
        ? demoUserData?.dashboardInsights
        : demoUserData?.programInsights;
  }

  // Always return local overrides if those are set.
  if (localOverrideInsights) {
    demoInsights = localOverrideInsights;
  }

  if (demoInsights) {
    const kpiSlugs = kpis.map(kpi => kpi.slug);
    // if demoInsights filter out insights to match current program kpis
    return demoInsights.filter(
      (insight: {
        kpi: {
          slug: string;
          friendlyName: string;
        };
        summary: {
          sum: number;
        };
      }) => {
        return kpiSlugs.includes(insight.kpi.slug);
      }
    );
  }

  return demoInsights;
};

interface GetDemoLeadsParams {
  userId: string;
  userEmail: string;
  appSettings: AppSettingsContextValue;
}

export const getDemoLeads = ({
  userId,
  userEmail,
  appSettings
}: GetDemoLeadsParams) => {
  const localOverrideUserLeads = appSettings?.app?.demoOverrides?.userLeads;

  let demoUserLeads = null;

  // Demo overrides for Demo Users.
  if (demoUserIds[userId] || userEmail === demoUserEmail) {
    demoUserLeads = demoUserData?.userLeads;
  }

  // Always return local overrides if those are set.
  if (localOverrideUserLeads) {
    demoUserLeads = localOverrideUserLeads;
  }

  return demoUserLeads?.edges || [];
};
