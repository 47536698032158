interface CrunchHelperTextNumbers {
  isMultiSelect: boolean;
  inputValue: any[] | string;
  options: any[];
  contactGroupCountResponse: any;
}

export const crunchHelperTextNumbers = ({
  isMultiSelect,
  inputValue,
  options,
  contactGroupCountResponse
}: CrunchHelperTextNumbers) => {
  let optionCount = options.length;
  let totalContactCount = contactGroupCountResponse?.totalRetrievedContactCount;

  if (isMultiSelect && inputValue && inputValue.length > 0) {
    optionCount = inputValue.length;

    // calculate total contact count from each selected option
    const currentSelectedOptions =
      contactGroupCountResponse?.contactGroupCounts?.filter((option: any) => {
        return inputValue.includes(option.groupId);
      });

    totalContactCount = currentSelectedOptions?.reduce(
      (acc: number, option: any) => acc + option.size,
      0
    );
  }

  if (!isMultiSelect && inputValue) {
    optionCount = 1;

    const currentSelectedOptions =
      contactGroupCountResponse?.contactGroupCounts?.filter((option: any) => {
        return inputValue === option.groupId;
      });

    totalContactCount = currentSelectedOptions?.reduce(
      (acc: number, option: any) => acc + option.size,
      0
    );
  }

  // translation expects this object with these keys
  return {
    optionCount,
    totalContactCount
  };
};
