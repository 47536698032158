import { ReactElement, useMemo } from 'react';
import { flow } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AppSettingsContextValue, withAppSettings } from 'src/AppSettings';

import AddCardElement from './AddCardElement';

interface AddCreditCardInjectedProps {
  appSettings: AppSettingsContextValue;
}

const AddCreditCard = (props: AddCreditCardInjectedProps) => {
  const { appSettings } = props;
  const stripeKey = appSettings?.app?.general?.stripeKey;

  const stripePromise = useMemo(() => loadStripe(stripeKey), []);

  return (
    <Elements stripe={stripePromise}>
      <AddCardElement />
    </Elements>
  );
};

export default flow(withAppSettings)(AddCreditCard as () => ReactElement);
