import { gql } from 'src/generated/gql';
import {
  FacebookAudienceAccountSelectionStrategy,
  FacebookAudienceAccountSharingRuleModel
} from 'src/generated/gql/graphql';

export const getResourceCollection = gql(`
  query GetResourceCollection($type: String!, $key: String!) {
    resourceCollection(type: $type, key: $key) {
      id
      type
      key
      description
      attributes
      resources {
        id
        value
        key
        description
        attributes
      }
    }
  }
`);

export const getResourceCollections = gql(`
  query GetResourceCollections($type: String!) {
    resourceCollections(type: $type) {
      id
      type
      key
      description
      attributes
    }
  }
`);

export interface FacebookAudienceAccountCreationRule {
  id: string;
  accountSelectionStrategy: FacebookAudienceAccountSelectionStrategy;
  audienceAccountSharingRules?:
    | FacebookAudienceAccountSharingRuleModel[]
    | null;
  userMetadataKey?: string | null;
  priority: number;
  group?: { id: string } | null;
  resourceCollection?: {
    id: string;
    key: string;
  } | null;
  resourceItem?: {
    id: string;
    key: string;
  } | null;
}

export const getFacebookAudienceAccountCreationRules = gql(`
  query FacebookAudienceAccountCreationRules($showDeleted: Boolean) {
    facebookAudienceAccountCreationRules(filter: { showDeleted: $showDeleted }) {
      id
      accountSelectionStrategy
      audienceAccountSharingRules {
        id
        resourceCollection {
            id
            key
            resources {
              id
              value
            }
          }
          resourceItem {
            id
            value
            key
          }
      }
      group {
        id
      }
      resourceCollection {
        id
        key
    
      }
      resourceItem {
        id
        key
      }
      userMetadataKey
      priority
    }
  }
`);

export interface AudienceCreationRule {
  id: string;
  slug: string;
  name: string;
  channelCode: string;
  description?: string;
  /**
   * This is an arbitrary chunk of JSON
   */
  requestSpec: unknown;
}

export const getAudienceCreationRules = gql(`
query AudienceCreationRules {
    audienceCreationRules(
        filter: { channelCodes: ["fb", "google"], showDeleted: false }
    ) {
        ... on GoogleUserListCreationRuleModel {
            id
            slug
            name
            channelCode
            description
            requestSpec
        }
        ... on FacebookAudienceCreationRuleModel {
            id
            slug
            name
            channelCode
            description
            requestSpec
        }
    }
}
`);
