import { ApolloClient, InMemoryCache, useQuery, gql } from '@apollo/client';

import Logger from 'src/common/Logger';
import { LITHIUM_THEME_URL } from 'src/api/Constants';

const client = new ApolloClient({
  uri: LITHIUM_THEME_URL,
  cache: new InMemoryCache()
});

export const useThemeOffice = () => {
  let { hostname } = window.location;
  const evHostnameOverride = localStorage.getItem('evHostnameOverride');

  if (evHostnameOverride) {
    hostname = evHostnameOverride; // eslint-disable-line no-param-reassign

    Logger.debug(
      'Overriding theme hostname with evHostnameOverride (from localStorage): ',
      hostname
    );
  } else if (import.meta.env.EVOCALIZE_IS_LOCAL) {
    hostname = 'office-staging.evocalize.com'; // eslint-disable-line no-param-reassign

    Logger.debug(
      'Overriding theme hostname with local (env var: EVOCALIZE_IS_LOCAL is true): ',
      hostname
    );
  }

  const SITELOAD_QUERY = gql`
    query getPublicSiteLoadV2 {
      public {
        siteLoadV2(fqdn: "${hostname}") {
            auth0Details {
                auth0SpaAudience
                auth0SpaCallbackUrl
                auth0SpaClientId
                auth0SpaDomain
                auth0SpaLogoutUrl
                auth0ConnectionType
                auth0ConfigurationBaseUrl
                auth0SpaResponseType
                auth0SpaScope
            }
            oktaDirectSSODetails {
                clientId
                authServerFqdn
                authorizationUrl
            }
            organizationFqdn
            organizationId
            organizationName
            facebookClientId
            skinSettings
            stripePublicKey
            authProviderType
            deactivationDate
            authenticationUrl
            logoutUrl
            isStripeConnect
            organizationTos {
              id
              link
              version

            }
            organizationPlgConfig {
              type
              userRegistration {
                  userField {
                      displayName
                      displayMethodId
                      displayParameters
                      name
                      isRequired
                  }
                  groupField {
                      displayName
                      displayMethodId
                      displayParameters
                      name
                      isRequired
                  }
                  emailField {
                      displayName
                      displayMethodId
                      displayParameters
                      name
                      isRequired
                  }
              }
          }
        }
      }
    }
  `;

  return useQuery(SITELOAD_QUERY, {
    client
  });
};
