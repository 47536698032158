import { Box, IconButton, Typography } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { t } from 'i18next';
import { find, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import {
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowId,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useForm, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import Modal, { ModalHeader, useModalState } from 'src/components/Modal';
import { ResourceCollection, ResourceItem } from 'src/generated/gql/graphql';
import { DynamicForm } from 'src/components/ReduxForm';
import DataTable from 'src/components/DataTable';

import { AccountSelectionStrategy } from '../../constants';
import {
  deleteFacebookAudienceAccountSharingRule,
  createFacebookAudienceAccountSharingRule
} from './mutations';
import useGetResourceCollectionItemOptions from './useGetResourceCollectionItemOptions';
import {
  ResourceCollections,
  formatResourceCollectionsOptions
} from './helpers';
import { SharingRule } from './ShareRuleValuesCell';
import { getColumns } from './shareRuleColumns';
import { getShareRuleInputs } from './shareRuleInputs';

export interface RowModel {
  id: string;
  audienceAccountSharingRules?: SharingRule[];
  resourceCollection?: ResourceCollection;
  resourceItem?: ResourceItem;
  accountSelectionStrategy: AccountSelectionStrategy;
  userMetadataKey?: string;
  priority: number;
  group?: {
    id: string;
  };
}

interface EditAudienceAccountShareRulesProps {
  row: RowModel;
  resourceCollections?: ResourceCollections;
  refetchCreationRules: () => Promise<void>;
}

const EditAudienceAccountShareRules = ({
  row,
  resourceCollections,
  refetchCreationRules
}: EditAudienceAccountShareRulesProps) => {
  const { open, openModal, closeModal } = useModalState();
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const gridApiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  interface FormValues {
    resourceCollectionId: string;
    resourceItemId: string;
  }

  const formMethods = useForm<FormValues>({
    defaultValues: {},
    mode: 'all',
    shouldUnregister: true
  });
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isValid, isSubmitting }
  } = formMethods;

  const selectedCollectionId = watch('resourceCollectionId');

  const resourceCollectionsOptions = formatResourceCollectionsOptions(
    resourceCollections || []
  );

  const selectedCollection = find(resourceCollections, {
    id: selectedCollectionId
  });

  const resourceItemOptions = useGetResourceCollectionItemOptions({
    selectedCollection,
    // Clear collection items input when collection changes
    callback: () => setValue('resourceItemId', '')
  });

  const [deleteSharingRule] = useMutation(
    deleteFacebookAudienceAccountSharingRule
  );

  const [createSharingRule, { loading: createSharingRuleLoading }] =
    useMutation(createFacebookAudienceAccountSharingRule);

  const onSubmit = (data: FormValues) => {
    setIsLoading(true);
    const creationRuleId = row.id;
    const { resourceCollectionId, resourceItemId } = data;

    const currentSharingRules = Array.from(
      gridApiRef.current?.getRowModels().values()
    );

    // If sharing rule already exists, do not create it, let the user know it's a duplicate.
    // The mutation will return success but will not create a duplicate share rule.
    const alreadyExists = find(currentSharingRules, (row: SharingRule) => {
      return (
        row.resourceCollection?.id === resourceCollectionId &&
        row.resourceItem?.id === (resourceItemId || undefined)
      );
    });

    if (alreadyExists) {
      enqueueSnackbar(
        t('facebookAccountCreationRulesForm:shareRules.ruleAlreadyExists'),
        { variant: 'error' }
      );
      setIsLoading(false);
      return;
    }

    createSharingRule({
      variables: {
        Input: { resourceCollectionId, resourceItemId, creationRuleId }
      }
    })
      .then(async result => {
        reset();
        enqueueSnackbar(
          t('facebookAccountCreationRulesForm:shareRules.createSuccess'),
          { variant: 'success' }
        );

        const newRow = result.data?.createFacebookAudienceAccountSharingRule;
        if (!isEmpty(newRow)) {
          gridApiRef.current?.updateRows([newRow]);

          await refetchCreationRules();
        }
      })
      .catch(() => {
        enqueueSnackbar(
          t('facebookAccountCreationRulesForm:shareRules.deleteSuccess'),
          { variant: 'error' }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleExpand = (id: string) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const getRowHeight = (row: GridRowHeightParams): GridRowHeightReturnValue => {
    const isExpanded = expandedRows[row.id];
    return isExpanded ? 'auto' : null;
  };

  const loadMoreRows = () => {
    const rows =
      row?.audienceAccountSharingRules?.map(shareRule => {
        return {
          ...shareRule,
          toggleExpand
        };
      }) || [];

    return Promise.resolve({
      rows,
      hasMoreRows: false
    });
  };

  const deleteRow = async (id: GridRowId) => {
    let success = false;
    let snackbarMessage = t(
      'facebookAccountCreationRulesForm:shareRules.deleteError'
    );
    let snackbarVariant: 'success' | 'error' = 'error';

    try {
      const res = await deleteSharingRule({
        variables: { ruleId: id as string }
      });
      success = !!res?.data?.deleteFacebookAudienceAccountSharingRule;

      if (success) {
        snackbarMessage = t(
          'facebookAccountCreationRulesForm:shareRules.deleteSuccess'
        );
        snackbarVariant = 'success';
      }

      enqueueSnackbar(snackbarMessage, {
        variant: snackbarVariant
      });

      if (success) {
        await refetchCreationRules();
      }
    } catch (e) {
      enqueueSnackbar(snackbarMessage, { variant: snackbarVariant });
    }

    return { success };
  };

  const columns = useMemo(
    () => getColumns({ expandedRows, isOverflowing, setIsOverflowing }),
    [expandedRows, isOverflowing]
  );

  const inputs = getShareRuleInputs({
    resourceCollectionsOptions,
    resourceItemOptions
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography>{row?.audienceAccountSharingRules?.length || 0}</Typography>
        <IconButton size="small" onClick={openModal}>
          <EditIcon fontSize="small" sx={{ fill: '#000' }} />
        </IconButton>
      </Box>
      <Modal
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={closeModal}
        showClose={false}
        HeaderComponent={() => (
          <ModalHeader>
            {t(
              'audienceTools:audienceAccountCreationRules.editShareRulesModalTitle'
            )}
          </ModalHeader>
        )}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography component="h3">
              {t(
                'audienceTools:audienceAccountCreationRules.editShareRulesTableTitle'
              )}
            </Typography>
            <DataTable
              customApiRef={gridApiRef}
              getRowHeight={getRowHeight}
              loadMoreRows={loadMoreRows}
              columns={columns}
              deleteConfirmationModal
              deleteRow={deleteRow}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'resourceCollection', sort: 'asc' }]
                }
              }}
              getRowId={row => row.id}
            />
          </Box>
          <FormProvider {...formMethods}>
            <Box
              onSubmit={e => {
                handleSubmit(onSubmit)(e).catch(() => {
                  enqueueSnackbar(
                    t(
                      'facebookAccountCreationRulesForm:shareRules.createError'
                    ),
                    {
                      variant: 'error'
                    }
                  );
                });
              }}
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'flex-start'
              }}
            >
              <Typography component="h3">
                {t(
                  'audienceTools:audienceAccountCreationRules.createShareRuleTitle'
                )}
              </Typography>
              <DynamicForm isHookForm inputs={inputs} />

              <LoadingButton
                sx={{ mt: 1 }}
                variant="contained"
                color="primary"
                type="submit"
                loading={createSharingRuleLoading || isSubmitting || isLoading}
                disabled={!isValid}
              >
                {t(
                  'audienceTools:audienceAccountCreationRules.createShareRuleButton'
                )}
              </LoadingButton>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
};

export default EditAudienceAccountShareRules;
