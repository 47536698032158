import { gql } from 'src/generated/gql';

export const deleteFacebookAudienceAccountSharingRule = gql(`
    mutation DeleteFacebookAudienceAccountSharingRule($ruleId: ID!) {
      deleteFacebookAudienceAccountSharingRule(ruleId: $ruleId)
    }
  `);

export const createFacebookAudienceAccountSharingRule = gql(`
    mutation createFacebookAudienceAccountSharingRule(
      $Input: CreateFacebookAudienceAccountSharingRuleInput!
    ) {
      createFacebookAudienceAccountSharingRule(input: $Input) {
        id
        resourceCollection {
          id
          key
          resources {
            id
            value
          }
        }
        resourceItem {
          id
          key
          value
        }
      }
    }
  `);
