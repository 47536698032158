import Numeral from 'numeral';
import { get, isNil, isFunction, isEqual, isString } from 'lodash';
import { formatNumber } from 'libphonenumber-js';

import { formatDate } from './dates';
import {
  CLOUDINARY_INVALID_SOURCE,
  CLOUDINARY_URL_PREFIX
} from './cloudinaryUtils';

export interface FormatterOptions {
  data: {
    root: unknown;
  };
  fn: (context: unknown) => boolean | unknown;
  inverse: (context: unknown) => boolean | unknown;
}

const isValidNumber = (number: any) => {
  return !isNil(number) && !Number.isNaN(number);
};

/**
 * Evaluates the condition and returns true/false if the handlebars expression
 * is not a part of an expression; otherwise, it invokes the corresponding
 * function or its inverse.
 *
 * @param condition The condition to evaluate.
 * @param options The template evaluation options.
 * @return Returns true, false, or the result of the nested expression.
 */
const conditionalEvaluator = (
  condition: boolean,
  options: FormatterOptions
) => {
  const context = options?.data?.root ?? {};
  if (condition) {
    return isFunction(options.fn) ? options.fn(context) : true;
  }

  return isFunction(options.inverse) ? options.inverse(context) : false;
};

export const DEFAULT_FORMATTERS = {
  price_decimal: 'CURRENCY',
  price_integer: 'CURRENCY'
};

const formatters = {
  CURRENCY: (data: number) => {
    if (!isValidNumber(data)) {
      return data;
    }

    // TODO: use a lib for this so we get the commas, number formatting etc...
    return Numeral(data).format('$0,0');
  },
  NUMBER: (data: number) => {
    if (!isValidNumber(data)) {
      return data;
    }

    return Numeral(data).format('0,0');
  },
  FLOAT: (data: number) => {
    if (!isValidNumber(data)) {
      return data;
    }

    return Numeral(data).format('0,0.[00]');
  },
  DATE: (data: Date) => {
    if (!data) {
      return '-';
    }

    return formatDate({ date: data, locale: 'US', type: 'CALENDAR' });
  },
  PHONE: (data: string) => {
    return formatNumber(data, 'US', 'International');
  },
  DEFAULT: (data: any | null, defaultData: any) => {
    if (data) {
      return data;
    }

    return defaultData;
  },

  // Note: The CLOUDINARY_* functions are meant to help in signing and
  //       rendering cloudinary images. Here's an example value that we would
  //       expect to see for a full cloudinary url:
  //       {{#CLOUDINARY_URL}} https://res.cloudinary.com/Evocalize/image/fetch/s--sig--/c_fill,h_628,w_1200/bo_3px_solid_rgb:ea491d,b_rgb:ea491d,co_white,g_north_east,x_50,y_30,l_text:Roboto%20Slab_30:{{CLOUDINARY_TEXT @root.__var__.description}}/v1469031598/{{CLOUDINARY_SOURCE_URL @root.__var__.imageUrl }}{{/CLOUDINARY_URL}}
  CLOUDINARY_URL: (options: any) => {
    // Note: for some reason, this function is never passed context AND
    //       options; only options. So to pull the parent context, we will
    //       pull the root out of data and pass that as context since it
    //       contains the information we need.
    const context = get(options, 'data.root', {});
    // Note: the backend's version of this function trim's the url so we do
    //       the same thing here. Sometimes the picture template can have
    //       whitespace at the end of the image so this will prevent that.
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return (CLOUDINARY_URL_PREFIX + options.fn(context)).trim();
  },
  CLOUDINARY_NAME_FOR_URL: (data?: string | null) => {
    // We need to parse the url thusly:
    // input: https://lithium-api-staging-uploads.s3.amazonaws.com/private/evocalize-staging/167859295021498385/video/f85c3b9612eb88d268d4b147ab0a0dc0.mp4
    // output: lithium-staging/private/evocalize-staging/167859295021498385/video/f85c3b9612eb88d268d4b147ab0a0dc0
    if (!data) {
      return CLOUDINARY_INVALID_SOURCE;
    }

    // We need to munge our url to a path and env for the backend
    // ignores first item "match" returns capture groups
    const [, env, path] = data.match(
      /https:\/\/lithium-api-(.+)-uploads\.s3\.amazonaws\.com\/(.+)/
    ) as any;

    if (!env || !path) {
      return CLOUDINARY_INVALID_SOURCE;
    }

    // eslint seems wrong?
    // eslint-disable-next-line no-useless-escape
    const pathWithoutExtension = path.replace(/(\.[^\.]+)$/, '');

    return encodeURIComponent(`lithium-${env}/${pathWithoutExtension}`);
  },
  // Cloudinary Text must be encoded twice.
  CLOUDINARY_TEXT: (data?: string | null, defaultData?: string | null) => {
    // Default cloudinary text to an empty string so we don't render "null"
    // in cloudinary images.
    let val = ' ';

    if (data) {
      val = data;
    } else if (defaultData) {
      val = defaultData;
    }

    return encodeURIComponent(encodeURIComponent(val));
  },
  // Cloudinary URLs must be encoded once.
  CLOUDINARY_SOURCE_URL: (data?: string | null) => {
    // This function requires that data be set. If it's an empty value (i.e.
    // an empty URL, then we return a specific key to inform the other parts
    // of our system that this is an invalid CLOUDINARY URL.
    if (!data) {
      return CLOUDINARY_INVALID_SOURCE;
    }

    return encodeURIComponent(data);
  },
  // Cloudinary sometimes needs us to base64 encode a url. This handles that
  // functionality.
  CLOUDINARY_BASE64: (url?: string | null, defaultUrl?: string | null) => {
    const val = url || defaultUrl;

    if (!val) {
      return val;
    }

    return btoa(val);
  },

  HAS_CLOUDINARY_NAME_FOR_URL: (url: string, options: FormatterOptions) => {
    // How this is being used:
    // {{#if (HAS_CLOUDINARY_NAME_FOR_URL "https://lithium-api-staging-uploads.s3.amazonaws.com/private/evocalize-staging/167859295021498385/video/f85c3b9612eb88d268d4b147ab0a0dc0.mp4")}}foo{{else}}bar{{/if}}
    // foo
    // {{#if (HAS_CLOUDINARY_NAME_FOR_URL "https://barUrl")}}foo{{else}}bar{{/if}}
    // bar
    // {{#HAS_CLOUDINARY_NAME_FOR_URL "https://lithium-api-staging-uploads.s3.amazonaws.com/private/evocalize-staging/167859295021498385/video/f85c3b9612eb88d268d4b147ab0a0dc0.mp4" }}foo{{else}}bar{{/HAS_CLOUDINARY_NAME_FOR_URL}}
    // foo
    // {{#HAS_CLOUDINARY_NAME_FOR_URL "https://badUrl" }}foo{{else}}bar{{/HAS_CLOUDINARY_NAME_FOR_URL}}
    // bar
    // {{HAS_CLOUDINARY_NAME_FOR_URL "https://lithium-api-staging-uploads.s3.amazonaws.com/private/evocalize-staging/167859295021498385/video/f85c3b9612eb88d268d4b147ab0a0dc0.mp4" }}
    // true
    // {{HAS_CLOUDINARY_NAME_FOR_URL "https://badUrl" }}
    // false

    if (!url) {
      return isFunction(options.inverse) ? options.inverse(this) : false;
    }

    const match = url.match(
      /https:\/\/lithium-api-(.+)-uploads\.s3\.amazonaws\.com\/(.+)/
    );

    if (match) {
      return isFunction(options.fn) ? options.fn(this) : true;
    }

    return isFunction(options.inverse) ? options.inverse(this) : false;
  },
  // only true if not nil and either 1) isn't a string or 2) is a non-blank string
  present: (input: any, options: FormatterOptions) =>
    conditionalEvaluator(
      !isNil(input) && (typeof input !== 'string' || input.trim().length > 0),
      options
    ),
  // this is from a backend filter
  // only false value if null not if empty string
  exists: (input: any, options: FormatterOptions) =>
    conditionalEvaluator(!isNil(input), options),
  eq: (lValue: any, rValue: any, options: FormatterOptions) => {
    return conditionalEvaluator(isEqual(lValue, rValue), options);
  },
  FALLBACK: (value: any, fallback: any, defaultValue: any) => {
    // example: {{ FALLBACK neighborhood city " " }}

    return value || fallback || defaultValue;
  },
  SPLIT_AND_TAKE: (
    value: string | null,
    splitKey: string,
    indexToShow: number
  ) => {
    // {{SPLIT_AND_TAKE __var__.addressAutocompleteList.[0].description ',' 0}}

    if (value) {
      return value.split(splitKey)[indexToShow];
    }
    return null;
  },

  REPLACE: (value: any, splitOn: string, joinWith: string) => {
    if (value && isString(value)) {
      const regex = new RegExp(splitOn, 'g');

      return value.replace(regex, joinWith);
    }
    return null;
  },

  if: (context: any, options: FormatterOptions) => {
    let truth;
    if (context === null || context === undefined) {
      truth = false;
    } else if (typeof context === 'boolean') {
      truth = context;
    } else if (typeof context === 'string') {
      const normalized = context.trim().toLowerCase();
      if (normalized === 'true' || normalized === 'yes') {
        truth = true;
      } else if (normalized === 'false' || normalized === 'no') {
        truth = false;
      } else {
        throw new Error(`Ambiguous string value: ${context}`);
      }
    } else if (typeof context === 'number') {
      truth = context !== 0;
    } else {
      throw new Error(
        `Unable to determine truthiness of "${context}" of type ${typeof context}`
      );
    }
    return conditionalEvaluator(truth, options);
  },

  or: (...conditions: any[]) => {
    //  filtering out any Handlebars options objects
    const filteredConditions = conditions.filter(
      cond => typeof cond === 'boolean'
    );

    return filteredConditions.some(Boolean);
  },

  and: (...conditions: any[]) => {
    //  filtering out any Handlebars options objects
    const filteredConditions = conditions.filter(
      cond => typeof cond === 'boolean'
    );

    return filteredConditions.every(Boolean);
  },

  contains: (context: any, searchString: any, options: FormatterOptions) => {
    if (!isString(context) || !isString(searchString)) {
      return conditionalEvaluator(false, options);
    }
    return conditionalEvaluator(context.includes(searchString), options);
  },

  not_contains: (
    context: any,
    searchString: any,
    options: FormatterOptions
  ) => {
    if (!isString(context) || !isString(searchString)) {
      return conditionalEvaluator(true, options);
    }
    return conditionalEvaluator(!context.includes(searchString), options);
  },

  PERCENT_ENCODE: (data: any) => {
    return isString(data) ? encodeURIComponent(data) : data;
  },

  BASE_URL: (data: any) => {
    if (!isString(data)) {
      return data;
    }

    try {
      const parsed = new URL(data);
      return `${parsed.protocol}//${parsed.host}`;
    } catch {
      return data;
    }
  },

  SLUGIFY: (data: any) => {
    if (!isString(data)) {
      return data;
    }

    return data
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  },

  TRIM: (data: any) => {
    return isString(data) ? data.trim() : data;
  },

  CLEANUP_URL: (url: any) => {
    if (!isString(url)) {
      return url;
    }

    let normalizedUrl = url;

    if (!/^https?:\/\//i.test(normalizedUrl)) {
      normalizedUrl = `http://${normalizedUrl}`;
    }

    try {
      const parsed = new URL(normalizedUrl);
      const {
        protocol,
        host,
        pathname: path,
        search: query,
        hash: fragment
      } = parsed;
      return `${protocol}//${host}${path}${query}${fragment}`;
    } catch {
      return url;
    }
  }
};

export default formatters;
