import { FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME } from 'src/pages/Program/Constants';

import Instrumentation from 'src/instrumentation';
import useProgram from 'src/pages/Program/utils/useProgram';

import AiLoadingSpinner from './AiLoadingSpinner';
import { InputField } from './helpers';

interface AiChatFooterProps {
  loading?: boolean;
  applyAllDisabled?: boolean;
  highlightInputs: (blueprintVariableIds?: string[]) => void;
  parentFormMethods: UseFormReturn<FieldValues, any, undefined>;
  inputs: InputField[];
  inputsCurrentSuggestion: Record<string, any>;
}

const AiChatFooter = ({
  loading = false,
  applyAllDisabled = true,
  highlightInputs,
  parentFormMethods,
  inputs,
  inputsCurrentSuggestion
}: AiChatFooterProps) => {
  const {
    selectedBlueprint,
    aiChatContext: { toggleAiChatWindow }
  } = useProgram();
  const aiChatFormValues = useWatch();

  const handleApplyAll = () => {
    Instrumentation.logEvent(Instrumentation.Events.ClickAiCopyApply, {
      input: 'All',
      productId: selectedBlueprint?.id,
      architectureId: selectedBlueprint?.architectureId
    });

    // Apply values from AI Chat Form to respective parent form form fields
    inputs.forEach(input => {
      const currentSuggestionIndex =
        inputsCurrentSuggestion[input?.blueprintVariableId] || 0;

      const value =
        aiChatFormValues[input?.blueprintVariableId]?.suggestions[
          currentSuggestionIndex
        ]?.text;

      parentFormMethods.setValue(
        `${PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME}.${input.fieldName}`,
        value
      );
    });

    parentFormMethods.setFocus(
      `${PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME}.${inputs[0].fieldName}`
    );

    toggleAiChatWindow();
  };

  return (
    <Box
      sx={{
        borderTop: theme => `1px solid ${theme.palette?.grey?.[300]}`,
        py: 1,
        px: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 53
      }}
    >
      {loading && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AiLoadingSpinner />
          <Typography color="GrayText">
            <Trans i18nKey="aiSuggestion:chat.loading" />
            ...
          </Typography>
        </Box>
      )}
      {inputs.length > 1 && (
        <Button
          data-cy="ai-chat-footer-apply-all-button"
          onClick={handleApplyAll}
          onMouseEnter={() => {
            highlightInputs();
          }}
          onMouseLeave={() => {
            highlightInputs([]);
          }}
          color="primary"
          sx={{ ml: 'auto' }}
          disabled={applyAllDisabled}
        >
          {t('aiSuggestion:chat.applyAllButton')}
        </Button>
      )}
    </Box>
  );
};

export default AiChatFooter;
