/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState, useCallback, useMemo } from 'react';
import { flow } from 'lodash';
import { reduxForm, SubmitHandler } from 'redux-form';
import { withApollo } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { ApolloClient } from '@apollo/client';

import { Button, Grid, Typography, Paper } from '@mui/material';
import { Theme } from '@mui/system';
import { withStyles } from '@mui/styles';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { AUTH_PROVIDER_TYPES, getAccessToken } from 'src/Auth/common';
import { useAppSettings } from 'src/AppSettings';
import { DynamicForm, FormAlert } from 'src/components/ReduxForm';
import useClientUrl from 'src/hooks/useClientUrl';
import useGetOrgGroup from 'src/hooks/useGetOrgGroups';
import {
  CopyToClipboardActionButton,
  CopyToClipboardContainer
} from 'src/components/CopyToClipboard/CopyToClipboard';
import Heading from 'src/components/PageElements/Heading';

import { WithStyledClasses } from 'src/common/Style';

import { EVOCALIZE_GROUP_ID, FORM_NAME } from './Constants';
import { generateSsoToken } from './queries';
import { createUserInputs } from './utilities';
import useCreateUser from './hooks/useCreateUser';

const styles = (theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(4)
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    width: '100%',
    gap: theme.spacing(2)
  },
  createNewUserButton: { marginTop: theme.spacing(2) },
  successButtons: {
    display: 'flex',
    gap: theme.spacing(2)
  }
});

const getText = () => ({
  successTitle: t('admin:createUser.successTitle'),
  successDescription: t('admin:createUser.successDescription'),
  createAnotherUser: t('admin:createUser.createAnotherUserButton'),
  errorTitle: t('admin:createUser.errorTitle'),
  errorDescription: t('admin:createUser.errorDescription')
});

interface AdminCreateUserProps {
  classes: WithStyledClasses<typeof styles>;
  handleSubmit: SubmitHandler;
  reset: () => void;
  client: ApolloClient<unknown>;
}

const AdminCreateUser = (props: AdminCreateUserProps) => {
  const { client, reset, classes, handleSubmit } = props;
  const text = useMemo(() => getText(), []);
  const { groups } = useGetOrgGroup();

  const [userToken, setUserToken] = useState<string | null>(null);
  const {
    myOrganization,
    appPermissions: { isEvocalizeOrg }
  } = useGlobalContext();
  const orgName = myOrganization?.name;

  const isCurrentEVUser = isEvocalizeOrg;

  const appSettings = useAppSettings();
  const { createUser, loading, error, data, isSuccess, setIsSuccess } =
    useCreateUser();
  const clientUrl = useClientUrl();
  const onSubmit = useCallback(
    data => {
      const createUserInOrgInput = {
        name: data?.name,
        email: data?.email,
        externalUserId: data?.email,
        groupId: isCurrentEVUser
          ? EVOCALIZE_GROUP_ID
          : data?.externalGroupId?.id,
        externalGroupId: !isCurrentEVUser
          ? data?.externalGroupId?.externalId
          : undefined,
        isOrgAdmin: isCurrentEVUser,
        isGroupAdmin: isCurrentEVUser,
        isSuperAdmin: isCurrentEVUser ? data?.isSuperAdmin : false
      };

      createUser({ variables: { createUserInOrgInput } });
    },
    [createUser, isCurrentEVUser]
  );

  const createToken = async (userId?: string) => {
    if (!userId) return;
    let response;
    try {
      response = await client.query({
        query: generateSsoToken,
        variables: {
          masterToken: getAccessToken(),
          targetUserId: userId
        },
        fetchPolicy: 'no-cache'
      });
    } catch (err) {
      throw new Error('Error generating token');
    }

    setUserToken(response?.data?.admin?.generateSsoToken?.token || null);
  };

  const handleClearForm = () => {
    setIsSuccess(false);
    setUserToken(null);
    reset();
  };

  const getFormDescription = (isCurrentEVUser: boolean) => {
    if (isCurrentEVUser)
      return t('admin:headings.createUserDescription.evUser');

    return t('admin:headings.createUserDescription.nonEvUser', { orgName });
  };

  const checkLink = `${clientUrl}/#/activate/check?email=${data?.createUserInOrganization?.email}`;

  const UserCreationSuccessful = () => {
    return appSettings?.app?.auth?.authProviderType ===
      AUTH_PROVIDER_TYPES.auth0 ? (
      <div className={classes.successContainer}>
        <Typography variant="body1">{text.successDescription}</Typography>
        <Typography data-cy="createUser-activation-link" variant="body1">
          {checkLink}
        </Typography>
        <div className={classes.successButtons}>
          <CopyToClipboardContainer>
            <CopyToClipboardActionButton text={checkLink} />
          </CopyToClipboardContainer>
        </div>
      </div>
    ) : (
      <div className={classes.successContainer}>
        <Typography data-cy="createUser-activation-link" variant="body1">
          <Trans i18nKey="admin:createUser.createUserToken">
            User Successfully created. Create a user token and share it with the
            new user so they can access their account
          </Trans>
        </Typography>
        <div className={classes.successButtons}>
          {userToken ? (
            <div>
              <span>{userToken}</span>
              <div className={classes.successButtons}>
                <CopyToClipboardContainer>
                  <CopyToClipboardActionButton text={userToken} />
                </CopyToClipboardContainer>
              </div>
            </div>
          ) : (
            <Button
              color="primary"
              onClick={() => createToken(data?.createUserInOrganization?.id)}
              variant="contained"
              data-cy="admin-create-user-button"
            >
              <Trans i18nKey="admin:createUser.createTokenButton">
                Create Token
              </Trans>
            </Button>
          )}
        </div>
      </div>
    );
  };

  const createUserInputsMemo = useMemo(() => {
    return createUserInputs(
      isCurrentEVUser,
      groups?.map(g => ({
        name: g.name,
        value: { externalId: g.externalId, id: g.id }
      }))
    );
  }, [isCurrentEVUser, groups]);

  const subtitle: string = getFormDescription(isCurrentEVUser);

  return (
    <>
      <Heading
        title={t('admin:headings.createUser')}
        subTitle={subtitle}
        pageTitle={t('admin:headings.createUser')}
      />

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {isSuccess ? (
              <FormAlert severity="success" title={text.successTitle}>
                <UserCreationSuccessful />
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.createNewUserButton}
                  data-cy="create-another-user-button"
                  onClick={handleClearForm}
                >
                  {text.createAnotherUser}
                </Button>
              </FormAlert>
            ) : (
              <Paper className={classes.paper}>
                {error && (
                  <FormAlert severity="error" title={text.errorTitle}>
                    <>
                      <Typography variant="body1">
                        {text.errorDescription}
                      </Typography>
                      <br />
                      {error?.toString()}
                    </>
                  </FormAlert>
                )}
                <div data-cy="admin-create-user-form">
                  <DynamicForm inputs={createUserInputsMemo} />
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    color="primary"
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    data-cy="admin-create-user-button"
                  >
                    <Trans i18nKey="admin:createUser.submitButton" />
                  </Button>
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default flow(
  withStyles(styles),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: true,
    initialValues: {
      name: '',
      email: '',
      isSuperAdmin: false
    }
  }),
  withApollo
)(AdminCreateUser);
