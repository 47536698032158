import { useState, useEffect } from 'react';
import { t } from 'i18next';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import { Paper, Typography, Grid, TextField, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DeleteIcon from '@mui/icons-material/Delete';

import formatters from 'src/common/formatters';
import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';

import { getApiSecrets } from './queries';
import { createAdminSecret, deleteAdminSecret } from './mutations';
import ConfirmModal from './ConfirmModal';

const styles = theme => ({
  content: {
    padding: theme.spacing(2)
  },
  clientKeyContent: {
    margin: `${theme.spacing(2)} 0`,
    padding: theme.spacing(2)
  },
  jwtSubtitle: {
    fontSize: '1.2rem'
  },
  secretFields: {
    marginTop: theme.spacing(2)
  },
  createBtn: {
    margin: `${theme.spacing(2)} 0`
  },
  showKeysBtn: {
    marginRight: theme.spacing(4),
    minWidth: '100px'
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  }
});

const getText = () => ({
  title: t('admin.clientKeys.title'),
  jwtInformationSubtitle: t('admin.clientKeys.jwtInformationSubtitle'),
  description: t('admin.clientKeys.description'),
  showKeys: t('admin.clientKeys.showKeys'),
  hideKeys: t('admin.clientKeys.hideKeys'),
  createKeys: t('admin.clientKeys.createKeys'),
  delete: t('admin.clientKeys.deleteKeys')
});

const ClientKeys = props => {
  const {
    classes,
    adminApiSecrets: adminApiSecretsData,
    createAdminSecret,
    deleteAdminSecret,
    jwtSigningSecret,
    refetch,
    loading
  } = props;
  const text = getText();
  const [adminApiSecrets, setAdminApiSecrets] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [clientKeyId, setClientKeyId] = useState(null);

  useEffect(() => {
    setAdminApiSecrets(adminApiSecretsData);
  }, [adminApiSecretsData]);

  const getInputType = showSecret => (showSecret ? 'text' : 'password');

  const handleKeysView = secretKeyId => () => {
    setAdminApiSecrets(prevAdminSecrets =>
      prevAdminSecrets?.map(secrets => {
        if (secretKeyId === secrets.clientKeyId) {
          return {
            ...secrets,
            showSecret: !secrets.showSecret
          };
        }
        return secrets;
      })
    );
  };

  const handleCreateKeys = () => {
    createAdminSecret();
    refetch();
    setIsDelete(null);
  };

  const handleDeleteKeys = () => {
    deleteAdminSecret({
      variables: {
        clientKeyId
      }
    });
    setClientKeyId(null);
    setIsDelete(null);
  };

  const handleConfirm = () => {
    if (isDelete) {
      handleDeleteKeys();
      return;
    }

    handleCreateKeys();
  };

  const handleOpenCreateModal = () => {
    setModalOpen(true);
    setIsDelete(false);
  };

  const handleOpenDeleteModal = () => {
    setModalOpen(true);
    setIsDelete(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Heading
        title={text.title}
        subTitle={text.description}
        pageTitle={text.title}
      />

      {adminApiSecrets?.map(secrets => (
        <Paper className={classes.clientKeyContent} key={secrets.clientKeyId}>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <TextField
                className={classes.secretFields}
                fullWidth
                id="clientKeyId"
                label="Client Key Id"
                variant="outlined"
                value={secrets.clientKeyId}
              />
              <TextField
                className={classes.secretFields}
                type={getInputType(secrets.showSecret)}
                fullWidth
                id="clientKeySecret"
                label="Client Key Secret"
                variant="outlined"
                value={secrets.clientKeySecret}
              />
              <Typography variant="body1" className={classes.secretFields}>
                Created On: {formatters.DATE(secrets.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.actionButtons}>
                <Button
                  size="small"
                  onClick={handleKeysView(secrets.clientKeyId)}
                  className={classes.showKeysBtn}
                >
                  {secrets.showSecret ? text.hideKeys : text.showKeys}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setClientKeyId(secrets.clientKeyId);
                    handleOpenDeleteModal();
                  }}
                >
                  {text.delete}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Button
        color="primary"
        variant="contained"
        onClick={handleOpenCreateModal}
        className={classes.createBtn}
      >
        {text.createKeys}
      </Button>

      <Paper className={classes.content}>
        <Typography className={classes.jwtSubtitle} variant="h4">
          {text.jwtInformationSubtitle}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={7}>
            <TextField
              className={classes.secretFields}
              fullWidth
              id="jwtInformation"
              label="JWT Information"
              variant="outlined"
              disabled={!jwtSigningSecret}
              value={jwtSigningSecret || 'No Internal Auth Config'}
            />
          </Grid>
        </Grid>
      </Paper>

      <ConfirmModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isDelete={isDelete}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default flow(
  graphql(getApiSecrets, {
    name: 'getApiSecrets',
    props: ({ getApiSecrets }) => {
      const adminApiSecrets =
        getApiSecrets?.myOrganization?.adminApiSecrets?.map(secrets => ({
          ...secrets,
          showSecret: false
        }));
      const jwtSigningSecret =
        getApiSecrets?.myOrganization?.internalAuthConfig?.jwtSigningSecret;

      return {
        adminApiSecrets,
        jwtSigningSecret,
        loading: getApiSecrets?.loading,
        refetch: getApiSecrets?.refetch
      };
    }
  }),
  graphql(createAdminSecret, {
    name: 'createAdminSecret',
    options: {
      refetchQueries: ['getApiSecrets'],
      awaitRefetchQueries: true
    }
  }),
  graphql(deleteAdminSecret, {
    name: 'deleteAdminSecret',
    options: {
      refetchQueries: ['getApiSecrets'],
      awaitRefetchQueries: true
    }
  }),
  withStyles(styles)
)(ClientKeys);
