import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import ShareRuleValuesCell, { SharingRule } from './ShareRuleValuesCell';

interface GetColumnsParams {
  expandedRows: Record<string, boolean>;
  isOverflowing: boolean;
  setIsOverflowing: (isOverflowing: boolean) => void;
}

export const getColumns = ({
  expandedRows,
  isOverflowing,
  setIsOverflowing
}: GetColumnsParams): GridColDef[] => [
  {
    field: '',
    headerName: '',
    width: 50,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    filterable: false,
    renderCell: ({ row }: GridRenderCellParams<SharingRule>) => {
      const { id } = row;

      const isExpanded = expandedRows[id] || false;
      const renderExpandButton = isExpanded || (isOverflowing && !isExpanded);

      if (!renderExpandButton) {
        return null;
      }
      return (
        <IconButton size="small" onClick={() => row?.toggleExpand(id)}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      );
    }
  },
  {
    align: 'left',
    disableColumnMenu: true,
    disableReorder: true,
    editable: true,
    field: 'resourceCollection',
    filterable: false,
    headerAlign: 'left',
    headerName: t(
      'facebookAccountCreationRulesForm:shareRules.tableHeader.resourceCollection'
    ),
    hideable: false,
    sortable: true,
    type: 'string',
    width: 200,
    renderCell: ({ row }: GridRenderCellParams<SharingRule>) => {
      return row?.resourceCollection?.key;
    }
  },
  {
    align: 'left',
    disableColumnMenu: true,
    disableReorder: true,
    editable: true,
    field: 'resourceItem',
    filterable: false,
    headerAlign: 'left',
    headerName: t(
      'facebookAccountCreationRulesForm:shareRules.tableHeader.resourceItem'
    ),
    hideable: false,
    sortable: false,
    type: 'string',
    width: 200,
    renderCell: ({ row }: GridRenderCellParams<SharingRule>) => {
      return row?.resourceItem?.key;
    }
  },
  {
    align: 'left',
    disableColumnMenu: true,
    disableReorder: true,
    editable: true,
    minWidth: 150,
    field: 'values',
    filterable: false,
    headerAlign: 'left',
    headerName: t(
      'facebookAccountCreationRulesForm:shareRules.tableHeader.values'
    ),
    hideable: false,
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<SharingRule>) => {
      return (
        <ShareRuleValuesCell
          row={row}
          expandedRows={expandedRows}
          setIsOverflowing={setIsOverflowing}
        />
      );
    }
  }
];
