import { find, isEmpty, map } from 'lodash';

import {
  FacebookAudienceAccountCreationRuleModel,
  FacebookAudienceAccountSelectionStrategy,
  ResourceCollection
} from 'src/generated/gql/graphql';

import { ACCOUNT_SELECTION_STRATEGIES } from '../../constants';

export interface FormValues {
  accountSelectionStrategy: FacebookAudienceAccountSelectionStrategy;
  priority: number;
  resourceCollectionId?: string;
  resourceItemId?: string;
  groupId?: string;
  userMetadataKey?: string;
}

interface FormatSubmissionDataArgs {
  formValues: FormValues;
}

export const formatSubmissionData = ({
  formValues
}: FormatSubmissionDataArgs) => {
  const accountSelectionStrategy = formValues?.accountSelectionStrategy;
  const data: FormValues = {
    accountSelectionStrategy,
    priority: formValues?.priority
  };

  switch (accountSelectionStrategy) {
    case ACCOUNT_SELECTION_STRATEGIES.ORG_DEFAULT:
      data.resourceCollectionId = formValues?.resourceCollectionId;
      data.resourceItemId = formValues?.resourceItemId;
      break;

    case ACCOUNT_SELECTION_STRATEGIES.GROUP:
      data.groupId = formValues?.groupId;
      data.resourceCollectionId = formValues?.resourceCollectionId;
      data.resourceItemId = formValues?.resourceItemId;
      break;

    case ACCOUNT_SELECTION_STRATEGIES.USER_METADATA:
      data.userMetadataKey = formValues?.userMetadataKey;
      break;

    default:
      break;
  }

  return data;
};

interface InputRuleData {
  accountSelectionStrategy: string;
  group?: { id?: string } | null;
  userMetadataKey?: string | null;
  resourceItem?: { id?: string } | null;
  resourceCollection?: { id?: string } | null;
}

export const getRowObjectFromFormValues = (formValues: FormValues) => {
  const inputRuleData: InputRuleData = {
    accountSelectionStrategy: formValues?.accountSelectionStrategy
  };

  switch (formValues?.accountSelectionStrategy) {
    case ACCOUNT_SELECTION_STRATEGIES.ORG_DEFAULT:
      inputRuleData.group = null;
      inputRuleData.userMetadataKey = null;
      inputRuleData.resourceItem = { id: formValues?.resourceItemId };
      inputRuleData.resourceCollection = {
        id: formValues?.resourceCollectionId
      };
      break;
    case ACCOUNT_SELECTION_STRATEGIES.GROUP:
      inputRuleData.userMetadataKey = null;
      inputRuleData.group = { id: formValues?.groupId };
      inputRuleData.resourceItem = { id: formValues?.resourceItemId };
      inputRuleData.resourceCollection = {
        id: formValues?.resourceCollectionId
      };
      break;
    case ACCOUNT_SELECTION_STRATEGIES.USER_METADATA:
      inputRuleData.group = null;
      inputRuleData.resourceCollection = null;
      inputRuleData.resourceItem = null;
      inputRuleData.userMetadataKey = formValues?.userMetadataKey;
      break;
    default:
      break;
  }
  return inputRuleData;
};

interface GetIsEditingExistingRuleArgs {
  formValues: FormValues;
  audienceAccountRules: FacebookAudienceAccountCreationRuleModel[];
  gridApiRef: { current: Record<string, any> };
}

export const getIsEditingExistingRule = ({
  formValues,
  audienceAccountRules,
  gridApiRef
}: GetIsEditingExistingRuleArgs) => {
  const inputRuleData = getRowObjectFromFormValues(formValues);

  const rule = find(audienceAccountRules, inputRuleData) as
    | FacebookAudienceAccountCreationRuleModel
    | undefined;

  if (!rule) {
    return false;
  }

  const row = isEmpty(gridApiRef.current)
    ? null
    : gridApiRef.current.getRow(rule?.id);

  return !!row;
};

export type ResourceCollections = Omit<ResourceCollection, 'resources'>[];

export const formatResourceCollectionsOptions = (
  resourceCollections: ResourceCollections
) => {
  return map(resourceCollections, collection => ({
    name: collection?.key,
    value: collection?.id
  }));
};
